import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/_auth/auth.service';
import { Cliente } from 'src/app/_class/users/cliente';

@Component({
  selector: 'app-carrito-encabezado',
  templateUrl: './carrito-encabezado.component.html',
  styleUrls: ['./carrito-encabezado.component.css']
})
export class CarritoEncabezadoComponent implements OnInit {

  public user$: Observable<any> = this.authSvc.afAuth.user;
  @Input() currentUser: Cliente = {};

  public hoy: string | number;
  public sucursales = [];
  public expresos = [];

  constructor(
    private authSvc: AuthService ) { }


  getfecha() {
    const fechaActual = new Date();
    const dia = fechaActual.getDate().toString();
    const mes = (fechaActual.getMonth() + 1).toString();
    const anio = fechaActual.getFullYear().toString();
    const hora = fechaActual.getHours().toString();
    const minutos = fechaActual.getMinutes().toString();
    const segundos = fechaActual.getSeconds().toString();
    this.hoy = dia + '/' + mes + '/' + anio;
    this.hoy += hora + ':' + minutos + ':' + segundos;

    const ret = this.hoy;

    return ret;
  }

  ngOnInit(): void {
    this.hoy = Date.now();
  }

}
