<div class="pt-4 container">
    <div class="row">
        <div class="col-lg-4 col-sm-12 col-12 text-center detail-part">
            <div class="row image-part m-0">
                <div class="col-lg-12 col-sm-12 col-12 user-img">
                    <div class="">
                        <img src="assets/profile.jpeg" class="border rounded-circle p-1">
                    </div>
                </div>

                <div class="pt-2 col-lg-12 col-sm-12 col-12 user-name">
                    <p>Developer</p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12 user-detail text-left p-2">
                    <p>Nombre Completo: <span>Daniel Rubén de Brito</span></p>
                    <p>Edad : <span>41 años</span></p>
                    <p>Email : <span>danielrdebrito@gmail.com</span></p>
                    <p>Locacion : <span>Lanús, Buenos Aires</span></p>
                </div>
                <div class="col-lg-12 col-sm-12 col-12 icon-section">
                    <a class="fb-ic" href="https://www.facebook.com/profile.php?id=100008288854505" target="_blank">
                        <i class="fa fa-facebook-f white-text mr-4"> </i>
                    </a>
                    <!-- Twitter -->
                    <a class="tw-ic" href="https://twitter.com/gronchostyle" target="_blank">
                        <i class="fa fa-twitter white-text mr-4"> </i>
                    </a>
                    <!-- Google +-->
                    <a class="gplus-ic">
                        <i class="fa fa-google white-text mr-4"> </i>
                    </a>
                    <!--Linkedin -->
                    <a class="li-ic" href="https://www.linkedin.com/in/danielrdebrito/" target="_blank">
                        <i class="fa fa-linkedin white-text mr-4"> </i>
                    </a>
                    <!--Instagram-->
                    <a class="ins-ic" href="https://github.com/danieldebrito?tab=repositories" target="_blank">
                        <i class="fa fa-github"> </i>
                    </a>
                </div>
            </div>
        </div>
        <div class="col-lg-8 detail-part">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12  col-12 bg-white rounded-top tab-head">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Acerca de mí</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Contacto</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="juego-tab" data-toggle="tab" href="#juego" role="tab" aria-controls="juego" aria-selected="false">Mi Juego</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-12 bg-white">
                        <div class="tab-content mt-4" id="myTabContent">
                            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <span>
                                            Estudiante de la tecnicatura en sistemas en la Universidad Tecnologina Nacional.
                                        </span>
                                    </div>
                                    <div class="col-lg-12 mt-2 about-detail">
                                        <h5 class="text-primary mb-1">Experiencia</h5>
                                        <span>Administrativo 24 Años</span><br><br>
                                        <span class="website"><kbd>www.juntasmeyro.com.ar</kbd></span>
                                        <h5>1996-hoy</h5>
                                        <span>
                                            • Dto. Compras: Gestión integral de compras e inventarios, desarrollo de nuevos proveedores, negociación de
                                            precios, condición de pago y plazos de entrega, contratación de servicios, seguimiento y análisis de plazos de
                                            entrega, calificación de proveedores, calidad y rechazos por proveedor, gestión de cuentas corrientes, generación
                                            órdenes de pago.
                                            <br>
                                            • Dto. Calidad: Normas ISO 9001 Confección de procedimientos, política, objetivos y manual de la calidad,
                                            indicadores, administración de documentación interna, realización de auditorías internas y atención de externas,
                                            gestión de no conformidades, acciones correctivas, preventivas “mejora continua”, capacitación sobre la filosofía de
                                            calidad de la empresa, auditores internos.
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="tab-pane fade contact-detail" id="profile" role="tabpanel" aria-labelledby="profile-tab">

                                <div class="col-lg-12 col-sm-12 col-12 icon-section">
                                    <a class="fb-ic" href="https://www.facebook.com/profile.php?id=100008288854505" target="_blank">
                                        <i class="fa fa-facebook-f white-text mr-4"> </i>
                                    </a>
                                    <!-- Twitter -->
                                    <a class="tw-ic" href="https://twitter.com/gronchostyle" target="_blank">
                                        <i class="fa fa-twitter white-text mr-4"> </i>
                                    </a>
                                    <!-- Google +-->
                                    <a class="gplus-ic">
                                        <i class="fa fa-google white-text mr-4"> </i>
                                    </a>
                                    <!--Linkedin -->
                                    <a class="li-ic" href="https://www.linkedin.com/in/danielrdebrito/" target="_blank">
                                        <i class="fa fa-linkedin white-text mr-4"> </i>
                                    </a>
                                    <!--Instagram-->
                                    <a class="ins-ic" href="https://github.com/danieldebrito?tab=repositories" target="_blank">
                                        <i class="fa fa-github"> </i>
                                    </a>
                                </div>
                            </div>

                            <div class="tab-pane fade contact-detail" id="juego" role="tabpanel" aria-labelledby="juego-tab">
                                <div class="col-lg-12">
                                    <span>
                                        ¿ De quien es la Casaca ?
                                    </span>
                                </div>
                                <span class="text-dark">
                                    El juego pone a prueba tus conociemientos de las ligas mundiales de futbol, precisamente sobre sus casacas Consiste en elegir mediante la foto de una casaca, de tres opciones a que club o pais pertenece, donde se deberá hacer click en el botón con
                                    la opción correcta, si sabes de futbol, demuéstralo.
                                    </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>