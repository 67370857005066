import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactFormComponent } from './pages/contact-form/contact-form.component';

const routes: Routes = [

  { path: 'about', component: AboutComponent },
  { path: 'pagenotfound', component: PageNotFoundComponent },
  { path: 'contacto', component: ContactFormComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule { }
