import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarritoRoutingModule } from './carrito-routing.module';
import { CarritoTablaComponent } from './components/carrito-tabla/carrito-tabla.component';
import { CarritoTicketComponent } from './components/carrito-ticket/carrito-ticket.component';
import { CarritoEncabezadoComponent } from './components/carrito-encabezado/carrito-encabezado.component';
import { CarritoPageComponent } from './pages/carrito-page/carrito-page.component';
import { CarritoNavButtonsComponent } from './components/carrito-nav-buttons/carrito-nav-buttons.component';
import { CarritoCargaManualComponent } from './components/carrito-carga-manual/carrito-carga-manual.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { PedidosDetalleComponent } from './pages/pedidos-detalle/pedidos-detalle.component';
import { MaterialModule } from 'src/app/material.module';
import { CarritoAdminPageComponent } from './pages/carrito-admin-page/carrito-admin-page.component';
import { CarritoAdminSelectClienteComponent } from './components/carrito-admin-select-cliente/carrito-admin-select-cliente.component';

@NgModule({
  declarations: [
    CarritoTablaComponent,
    CarritoTicketComponent,
    CarritoEncabezadoComponent,
    CarritoPageComponent,
    CarritoNavButtonsComponent,
    CarritoCargaManualComponent,
    PedidosDetalleComponent,
    CarritoAdminPageComponent,
    CarritoAdminSelectClienteComponent
  ],
  imports: [
    CommonModule,
    CarritoRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule
  ],
  exports: [
    PedidosDetalleComponent,
    CarritoPageComponent,
    CarritoAdminPageComponent
  ]
})
export class CarritoModule { }
