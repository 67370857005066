<div class="container">
    <div class="card mt-5" *ngIf="user$ | async as user; else loading">
        <div class="card-body">
            <h3 class="card-title text-center">Gracias por crear tu cuenta!</h3>
            <div class="form-group mt-2">
                <p class="text-center">
                    Por favor verificá tu dirección de email <strong> {{ user?.email }}</strong>
                </p>
                <p class="text-center">
                    Rerevisa la casilla de correo no deseado e ingresá al link de verificación
                </p>
            </div>
            <div class="form-group text-center mt-5">
                <button class="btn btn-primary" (click)="onSendEmail()">Volver a enviar email de confirmación</button>
            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    loading.....
</ng-template>