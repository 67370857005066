<div>
  <div class="row">
    <div class="col-sm-7">
      <h2>{{ pedido.idCliente }} - {{ getRazonSocial(pedido.idCliente) }} </h2>
    </div>

    <div class="col-sm-2">
      <span [ngClass]="clase">
        {{ estadoDetalle }}
      </span>
    </div>

    <div class="col-sm-3">
      <button type="submit" class="btn btn-success" (click)="exportToExcel()" [disabled]="!isDataLoaded">
        <img class="icon" src="./assets/icons/file-excel-solid.svg" alt="" srcset="">
        Exportar Excel
      </button>
    </div>
  </div>

  <div class="row">
    <div class="btn-toolbar">
      <!-- Verificación del role del usuario -->
      <div *ngIf="isAdmin">
        <button type="button" class="btn btn-success btn-sm" data-bs-toggle="tooltip" data-bs-placement="top"
          title="Marcar Como Leído" (click)="actualizarEstado(EEstadoPedido.leido, pedido)">
          <i class="fa fa-envelope"></i>
        </button>&nbsp;
        <button type="button" class="btn btn-info btn-sm" data-bs-toggle="tooltip" data-bs-placement="top"
          title="Marcar Como No Leído" (click)="actualizarEstado(EEstadoPedido.noleido, pedido)">
          <i class="fa fa-envelope-open"></i>
        </button>&nbsp;
        <button type="button" class="btn btn-danger btn-sm" data-bs-toggle="tooltip" data-bs-placement="top"
          title="Marcar Como Anulado" (click)="actualizarEstado(EEstadoPedido.anulado, pedido)">
          <i class="fa fa-window-close"></i>
        </button>&nbsp;
      </div>
      <!-- Este botón siempre visible -->
      <button type="button" class="btn btn-success btn-sm" data-bs-toggle="tooltip" data-bs-placement="top">
        <a (click)="volverAPedidos()">Volver a Pedidos</a>
      </button>
    </div>

    <div class="col-sm-9 mt-2">
      <app-carrito-tabla [currentUser]="currentUser" [carritoItems]="pedidoItems">
      </app-carrito-tabla>

      <hr>

      <div class="row">
        <div class="col-sm-12">
          <textarea placeholder="Observaciones..." class="form-control" rows="4" name="observaciones"></textarea>
        </div>
      </div>
    </div>

    <div class="mt-2 col-sm-3">
      <app-carrito-ticket [soloDetalle]="true" [pedidoItems]="pedidoItems"></app-carrito-ticket>
    </div>
  </div>

  <ng-template #loading>
    <mat-progress-spinner></mat-progress-spinner>
  </ng-template>
</div>
