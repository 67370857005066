import { Cliente } from 'src/app/_class/users/cliente';
import { User } from 'src/app/_auth/models/user.interface';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
// import { auth } from 'firebase/app';
import firebase from 'firebase/app'
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { RoleValidator } from 'src/app/_auth/helpers/roleValidator';
import { EmpleadosPHPService } from '../_services/php/empleadossPHP.service';
import { Empleado } from '../_class/users/empleado';
import { clientesPHPservice } from '../_services/php/clientesPHP.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends RoleValidator {
  public user$: Observable<User>;

  private cliente: Cliente = {};
  private clientes: Cliente[] = [];

  constructor(
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private clientesSvPHP: clientesPHPservice,
    private empleadoSv: EmpleadosPHPService
  ) {
    super();
    this.user$ = this.afAuth.authState.pipe(
      switchMap((user) => {
        if (user) {
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        }
        return of(null);
      })
    );
  }

  async resetPassword(email: string): Promise<void> {
    try {
      return this.afAuth.sendPasswordResetEmail(email);
    } catch (error) {
      console.log(error);
    }
  }

  async sendVerificationEmail(): Promise<void> {
    return (await this.afAuth.currentUser).sendEmailVerification();
  }

  async login(email: string, password: string): Promise<User> {
    try {
      const { user } = await this.afAuth.signInWithEmailAndPassword(
        email,
        password
      );
      this.updateUserDataLogin(user, password);

      return user;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public updateUserDataLogin(user, password) {
    this.clientesSvPHP.gets().subscribe(res => {
      this.clientes = res;
      this.cliente = this.clientes.find(c => c.email == user.email);
      this.cliente.password = password;
      this.cliente.emailVerified = user.emailVerified;
      this.cliente.uid = user.uid;

      this.updateUserData(this.cliente);
    });
  }

  async register(cliente: Cliente) {
    try {
      await this.afAuth.createUserWithEmailAndPassword(
        cliente.email,
        cliente.password
      ).then((userCredential) => {

        // Signed in
        var user = userCredential.user;

        cliente.emailVerified = user.emailVerified;
        cliente.uid = user.uid;

        this.clientesSvPHP.update(cliente.id, cliente).subscribe(res => {
          console.log(res);
        });

        this.sendVerificationEmail();

        return user;

      }).catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        // ..
      });

    } catch (error) {
      console.log(error);
      return null;
    }
  }

  private updateUserData(cliente: Cliente) {

    this.clientesSvPHP.update(cliente.id, cliente).subscribe(res => {
      console.log(res);
    });
  }

  async registerEmpleado(emp: Empleado): Promise<User> {
    try {
      const { user } = await this.afAuth.createUserWithEmailAndPassword(
        emp.email,
        emp.password
      );

      emp.emailVerified = user.emailVerified;
      emp.uid = user.uid;

      this.updateUserDataEmpleado(emp);

      await this.sendVerificationEmail();

      return user;

    } catch (error) {
      console.log(error);
      return null;
    }
  }

  private updateUserDataEmpleado(emp: Empleado) {
    console.log(emp);
    this.empleadoSv.save(emp)
      .subscribe(
        res => {
          console.log(res);
        },
        err => console.error(err)
      )
  }

  async logout(): Promise<void> {
    try {
      await this.afAuth.signOut();
    } catch (error) {
      console.log(error);
    }
  }
}

