  <div class="row">

    <div class="col-sm-12">

      <table class="table table-hover table-bordered table-sm">
        <tbody>
          <tr  *ngFor="let prod of productos">
            <div class="row mt-3">

              <div class="col-sm-8 mt-3">
                <app-descurntos-producto-detalle (lanzaDescuentoCategorizado)="create($event)" 
                [viewUser]="viewUser" [producto]="prod" [descuentos]="descuentos" [descuentosCliente]="descuentosCliente" ></app-descurntos-producto-detalle>
              </div>

            </div>
          </tr>
        </tbody>
      </table>
    </div>
  </div>