<div class="container p-2">
    <div class="col-md-12">
        <ul class="nav nav-pills mb-3">
            <li class="nav-item">
                <a class="btn btn-outline-danger" routerLink='/catalogo'>
                    <strong>Catalogo</strong>
                </a>&nbsp;&nbsp;
            </li>

            <li class="nav-item">
                <a class="btn btn-outline-danger" routerLink="/carrito">
                    <i class="fa fa-cart-plus"></i>&nbsp;
                    <strong>Carrito</strong>
                </a>&nbsp;&nbsp;
            </li>
            <li class="nav-item">
                <a class="btn btn-outline-danger" routerLink="/pedidos">
                    <strong>Pedidos</strong>
                </a>&nbsp;&nbsp;
            </li>
        </ul>
    </div>
</div>