import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from './_auth/guards/admin.guard';
import { ClienteGuard } from './_auth/guards/cliente.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  { path: '', loadChildren: () => import('./web/catalogo/catalogo.module').then(m => m.CatalogoModule) }, 
  { path: '', loadChildren: () => import('./web/carrito/carrito.module').then(m => m.CarritoModule) }, 
  { path: 'carrito', loadChildren: () => import('./web/carrito/carrito.module').then(m => m.CarritoModule) },
  { path: '', loadChildren: () => import('./web/pedidos/pedidos.module').then(m => m.PedidosModule) },
  { path: '', loadChildren: () => import('./web/admin/admin.module').then(m => m.AdminModule) },
  { path: '', loadChildren: () => import('./web/nosotros/nosotros.module').then(m => m.NosotrosModule) },
  { path: '', loadChildren: () => import('./web/dtotecnico/dtotecnico-routing.module').then(m => m.DtotecnicoRoutingModule) },
  { path: '', loadChildren: () => import('./web/cliente/cliente.module').then(m => m.ClienteModule) },
  { path: 'lanzamientos', loadChildren: () => import('./web/catalogo/pages/lanzamientos/lanzamientos.module').then(m => m.LanzamientosModule) },
  { path: 'home', loadChildren: () => import('./web/home/home.module').then(m => m.HomeModule)  },
  { path: 'precioscliente', loadChildren: () => import('./web/catalogo/pages/precios-cliente/precios-cliente.module').then(m => m.PreciosClienteModule) },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
