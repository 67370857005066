export enum EEstadoPedido {
  leido = 1,
  noleido = 2,
  anulado = 3,
  entregado = 4
}

export class Pedido {
  constructor(
    public id?: number,
    public idCliente?: string,
    public idSucursal?: number,
    public idExpreso?: number,
    public estado?: number,
    public observaciones?: string,
    public fechaHora?: string
  ) { }
}
