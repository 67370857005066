<li class="nav-item">
    <a class="nav-link active" aria-current="page" routerLink="/home">Home</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="/catalogo">Catalogo</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="/carrito">Carrito</a>
  </li>
  <!--
  <li class="nav-item">
    <a class="nav-link" routerLink="/productos">Productos</a>
  </li>
  -->
  <li class="nav-item">
    <a class="nav-link" routerLink="/contacto">Contacto</a>
  </li>