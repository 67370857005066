import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Descuento } from 'src/app/_class/order/descuento';
import { Producto } from 'src/app/_class/products/producto';
import { Cliente } from 'src/app/_class/users/cliente';
import { DescuentoCliente } from 'src/app/_class/order/descuentoCliente';

@Component({
  selector: 'app-cliente-descuentos',
  templateUrl: './cliente-descuentos.component.html',
  styleUrls: ['./cliente-descuentos.component.scss']
})
export class ClienteDescuentosComponent implements OnInit {

  @Input() descuentos: Descuento[] = [];
  @Input() productos: Producto[] = [];
  @Input() descuentosCliente: DescuentoCliente[] = [];
  @Input() viewUser: Cliente = {};

  @Output() lanzaCreateDto = new EventEmitter();

  constructor() { }

  public create(event) {
    this.lanzaCreateDto.emit(event);
  }

  ngOnInit(): void { }
}
