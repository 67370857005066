import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UsuariosComponent } from './usuarios.component';

import { AdminGuard } from 'src/app/_auth/guards/admin.guard';

import { Cliente } from 'src/app/_class/users/cliente';
import { ClienteGuard } from 'src/app/_auth/guards/cliente.guard';


const routes: Routes = [
  { path: '', component: UsuariosComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsuariosRoutingModule { }
