import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, } from '@angular/forms';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/_auth/auth.service';
import { Cliente } from 'src/app/_class/users/cliente';
import { ArticulosService } from 'src/app/_services/articulos.service';
import { clientesPHPservice } from 'src/app/_services/php/clientesPHP.service';
// import { ClientesService } from 'src/app/_services/clientes.service';

@Component({
  selector: 'app-carrito-carga-manual',
  templateUrl: './carrito-carga-manual.component.html',
  styleUrls: ['./carrito-carga-manual.component.css']
})
export class CarritoCargaManualComponent implements OnInit {

  @Output() lanzaPedido = new EventEmitter();
  @Output() generarExcel = new EventEmitter();

  @Input() currentUser: Cliente = {};



  public clientes: Cliente[] = [];
  public cliente: Cliente = {};



  //public user$: Observable<any> = this.authSvc.afAuth.user;
  public mensaje = '';

  altaForm = new FormGroup({
    cantidad: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(5),
      Validators.pattern('[0-9]{1,5}'),
    ]),
    idArticulo: new FormControl('', [
      Validators.required,
      // Validators.minLength(2),
      // Validators.maxLength(40),
      // Validators.pattern('[a-zA-Z ]{2,41}'),
    ]),
  });

  constructor(
    private authSvc: AuthService,
    private clientesPHPSv: clientesPHPservice,
    private ArticulosSv: ArticulosService,
  ) { }

  public onSubmit() {

    this.clientesPHPSv.gets().subscribe(ctes => {
      this.clientes = ctes;


      this.ArticulosSv.getById(this.altaForm.getRawValue().idArticulo).subscribe((art) => {

        if (art.idArticulo) {
          this.lanzaPedido.emit({
            cantidad: this.altaForm.getRawValue().cantidad,
            articulo: art,
            cliente: this.currentUser, // this.clientes.find(cte => cte.uid = currentUser.uid),
            abierto: true,
          });

          this.altaForm.reset();

        } else {
          this.mensaje = "Este Articulo No Existe";
        }


      });


      /*
            this.clientesPHPSv.getClienteByUid(res.uid).subscribe((cu) => {
              this.ArticulosSv.getById(this.altaForm.getRawValue().idArticulo).subscribe((art) => {
      
                if (art.idArticulo) {
                  this.lanzaPedido.emit({
                    cantidad: this.altaForm.getRawValue().cantidad,
                    articulo: art,
                    cliente: cu,
                    abierto: true,
                  });
      
                  this.altaForm.reset();
      
                } else {
                  this.mensaje = "Este Articulo No Existe";
                }
              });
            });
      
            */


    });
  }

  public lanzaGenerarExcel() {
    this.generarExcel.emit();
  }

  public resetMensaje() {
    this.mensaje = '';
  }

  ngOnInit(): void { }
}
