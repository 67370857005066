import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-carrito-nav-buttons',
  templateUrl: './carrito-nav-buttons.component.html',
  styleUrls: ['./carrito-nav-buttons.component.css']
})
export class CarritoNavButtonsComponent {

  @Output() verListado = new EventEmitter();

  constructor() { }

  public capCliente(event) {
    this.verListado.emit({ cliente: event });
  }

}
