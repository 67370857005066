import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pass-user-incorrecto',
  templateUrl: './pass-user-incorrecto.component.html',
  styleUrls: ['./pass-user-incorrecto.component.css']
})
export class PassUserIncorrectoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
