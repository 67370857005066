import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/_auth/auth.service';
import { Router } from '@angular/router';
import { User } from 'src/app/_auth/models/user.interface';
import { Observable } from 'rxjs';
import { Cliente } from 'src/app/_class/users/cliente';
import { clientesPHPservice } from 'src/app/_services/php/clientesPHP.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {

  public user$: Observable<any> = this.authSvc.afAuth.user;
  public currentUser: Cliente = {};
  public clientes: Cliente[] = [];

  public error = false;
  public mostrarPass = false;

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
    ]),
  });

  constructor(
    private authSvc: AuthService,
    private router: Router,
    private clientesSv: clientesPHPservice) { }

  async onLogin(): Promise<void> {
    const { email, password } = this.loginForm.value;
    try {
      const user = await this.authSvc.login(email, password);
      if (user) {
        this.checkUserIsVerified(user);
      } else {
        this.error = true;
        // this.router.navigate(['/pass-email-incorrecto']);
      }
    } catch (error) {
      console.log(error);
    }
  }


  ////////////////////////////////////////////////////////////////////////////////////////////////////////  SEGUIR //////


  private checkUserIsVerified(user: User): void {
    this.user$.subscribe((current) => {
      this.clientesSv.gets().subscribe(res => {
        this.currentUser = this.clientes.find(c => c.uid == current.uid);

        // se da la opcion de saltear la verificacion del mail ya que en algunos casos se complica que validen
        // por poco conocimiento de los usuarios. this.currentUser.emailVerified == true

        if ((user && user.emailVerified) || (user && this.currentUser.emailVerified == true)) {
          this.router.navigate(['/home']);
        } else if (user) {
          this.router.navigate(['/verification-email']);
        } else {
          this.router.navigate(['/registro']);
        }
      });
    });
  }

  public errorFalse(): void {
    this.error = false;
  }

  verOcultarPass() {
    this.mostrarPass = !this.mostrarPass;
  }
}
