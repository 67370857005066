import { Cliente } from 'src/app/_class/users/cliente';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/_auth/auth.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CarritoItemsService } from 'src/app/_services/firebase/carrito-items.service';
import { EmpleadosPHPService } from 'src/app/_services/php/empleadossPHP.service';
import { Empleado } from 'src/app/_class/users/empleado';
import { Estado } from 'src/app/_class/users/usuario';
import { clientesPHPservice } from 'src/app/_services/php/clientesPHP.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  public user$: Observable<any> = this.authSvc.afAuth.user;
  public currentUser: Cliente = {};

  public isEmpleado: boolean = false;
  public isCliente: boolean = false;

  public empleados: Empleado[] = [];
  public clientes: Cliente[] = [];

  public isAdmin: boolean = false;
  public cantItemsCarrito = 0;

  constructor(
    public authSvc: AuthService,
    private carritoItemsSv: CarritoItemsService,
    private router: Router,
    private empleadoSv: EmpleadosPHPService,
    private clientesPHPSv: clientesPHPservice
  ) { }

  async onLogout() {
    try {
      await this.authSvc.logout();
      this.router.navigate(['/login']);
    } catch (error) {
      console.log(error);
    }
  }

  public getCarritoItems() {
    this.carritoItemsSv.getItems().subscribe((res) => {


      this.user$.subscribe((current) => {

        this.empleadoSv.gets().subscribe(res => {
          this.empleados = res;
          if (this.empleados.find(e => e.uid == current.uid && e.estado == Estado.habilitado)) {
            this.isEmpleado = true;
          } else {
            this.isEmpleado = false;
          }
        });



        this.clientesPHPSv.gets().subscribe(res => {
          this.clientes = res;
          if (this.clientes.find(e => e.uid == current.uid && e.estado == Estado.habilitado)) {
            this.isCliente = true;
          } else {
            this.isCliente = false;
          }
        });



      });
    });
  }

  /*
    public getCurrentUser() {
      this.user$.subscribe((res) => {
        this.clientesSv.getClienteByUid(res.uid).subscribe((cu) => {
          this.currentUser = cu;
          this.isAdmin = (cu.role == 2);
          // console.log(res);
          return cu;
        });
      });
    }
     */

  private checkIsEmpleado() {
    this.user$.subscribe((current) => {
      this.empleadoSv.gets().subscribe(res => {
        this.empleados = res;
        if (this.empleados.find(e => e.uid == current.uid && e.estado == Estado.habilitado)) {
          this.isEmpleado = true;
        } else {
          this.isEmpleado = false;
        }
      });
    });
  }

  private checkCliente() {
    this.user$.subscribe((current) => {
      this.clientesPHPSv.gets().subscribe(res => {
        this.clientes = res;
        if (this.clientes.find(e => e.uid == current.uid && e.estado == Estado.habilitado)) {
          this.isCliente = true;
        } else {
          this.isCliente = false;
        }
      });
    });
  }

  ngOnInit(): void {
    this.checkCliente();
    this.getCarritoItems();
    // this.getCurrentUser();
    this.checkIsEmpleado();
  }
}
