import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/_auth/auth.service';

import { Cliente } from 'src/app/_class/users/cliente';
import { Descuento } from 'src/app/_class/order/descuento';
import { dtosCliente } from 'src/app/_class/order/dtosCliente';

// import { DescuentosService } from 'src/app/_services/descuentos.service';
import { DescuentosPHPService } from 'src/app/_services/php/descuentosPHP.service';
import { clientesPHPservice } from 'src/app/_services/php/clientesPHP.service';
import { Producto } from 'src/app/_class/products/producto';
import { ProductosPHPService } from 'src/app/_services/php/productosPHP.service';
import { descuentosClientePHPService } from 'src/app/_services/php/descuentosClientePHP.service';


@Component({
  selector: 'app-perfil-page',
  templateUrl: './perfil-page.component.html',
  styleUrls: ['./perfil-page.component.css']
})
export class PerfilPageComponent implements OnInit {

  @Input() user$: Observable<any> = this.authSvc.afAuth.user;
  @Input() currentUser: Cliente = {};

  public descuento: Descuento = {};
  public descuentos: Descuento[] = [];

  public Dto: dtosCliente = {};
  public clientes: Cliente[] = [];

  public descuentosCliente: dtosCliente[] = [];


  public idDescuento: number = 0;
  public isAdmin: boolean = false;

  public productos: Producto[] = [];

  descuentoForm = new FormGroup({
    descripcion: new FormControl('', [Validators.required]),
    coeficiente: new FormControl('', [Validators.required]),
    descuento: new FormControl(''),
  });

  constructor(
    private authSvc: AuthService,
    private clientesPHPsv: clientesPHPservice,
    private productosPHPsv: ProductosPHPService,
    private descuentosSv: DescuentosPHPService,
    private descuentosClientePHPSv: descuentosClientePHPService
  ) { }

  public getCurrentUser() {
    this.user$.subscribe((res) => {
      this.clientesPHPsv.gets().subscribe(cts => {
        this.clientes = cts;
        this.currentUser = this.clientes.find(c => c.uid == res.uid);


        this.getsDescuentosCliente(this.currentUser);
      });
    });
  }

  public getDescuentos() {
    this.descuentosSv.gets().subscribe(res => {
      this.descuentos = res.filter(e => e.habilitado == 1);
      console.log(this.descuentos);
    });
  }

  public getsDescuentosCliente(currentCliente: Cliente){
    this.descuentosClientePHPSv.gets().subscribe( res => {

      let descuentosClientetTEMP: dtosCliente[] = res;

      this.descuentosCliente = descuentosClientetTEMP.filter( ctesDtos => ctesDtos.idCliente == currentCliente.idCliente );
    });
  }

  public getsProductos(){
    this.productosPHPsv.gets().subscribe( res => {
      this.productos = res;
    });
  }

/*
  public updateIdDescuentoCliente() {
    this.currentUser.tieneDtoCateg = this.descuentoForm.get('descuento').value.id;
    this.clientesSv.updateCliente(this.currentUser.id, this.currentUser).subscribe(res => {
      console.log(res);
      this.getCurrentUser();
    });
  }*/

  public getDtosCliente() {
    this.descuentosSv.gets().subscribe(res => {
      this.descuentos = res;
      console.log(res);
    });
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.getCurrentUser();
    // this.getDescuentos();
    this.getDtosCliente();
    this.getsProductos();
  }
}
