import { Component, Input, OnInit } from '@angular/core';
import { Cliente } from 'src/app/_class/users/cliente';

@Component({
  selector: 'app-carrito-admin-select-cliente',
  templateUrl: './carrito-admin-select-cliente.component.html',
  styleUrls: ['./carrito-admin-select-cliente.component.scss']
})
export class CarritoAdminSelectClienteComponent implements OnInit {

  @Input() clientes: Cliente[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
