<div class="row">
  <div class="col-sm-3">
    <p class="fs-5"> {{ producto.producto }} </p>
  </div>

  <div class="col-sm-3">
    <p class="fs-6">Base: <b>{{ descuento.descripcion }} %</b> </p>
  </div>

  <div class="col-sm-3">
    <h5 class="fs-6"> Categorizado: </h5>
  </div>

  {{ getDtoProducto(producto) }}

  <div class="col-sm-3" *ngIf="change">
    <div class="row">
      <div class="col">
        <p class="fs-6"> {{ descuentoCat.descripcion }} % </p>
      </div>
      <div class="col">
        <button type="button" class="btn btn-primary btn-sm" (click)="update()">
          <i class="fa fa-pencil"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="col-sm-3" *ngIf="!change">
    <select id="inputState" class="form-control" (change)="updateClienteDtoCategorizado($event)">
      <option selected value=""> Cambiar Descuento </option>
      <option value="{{ item.id }}" *ngFor="let item of descuentos"> {{ item.descripcion }} </option>
    </select>
  </div>
</div>
