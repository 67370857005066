<div class="container">
  <!-- Section: Contact v.1 -->
<section class="my-5">

  <!-- Section heading -->
  <h2 class="h1-responsive font-weight-bold text-center my-5">Contacto</h2>


  <!-- Grid row -->
  <div class="row">

    <!-- Grid column -->
    <div class="col-lg-5 mb-lg-0 mb-4">

      <!-- Form with header -->
      <div class="card">
        <div class="card-body">
          <!-- Header -->
          <div class="form-header blue accent-1">
            <h3 class="mt-2"><i class="fa fa-envelope"></i> Escríbenos :</h3>
          </div>
          <!-- Body -->

          <div class="md-form  mt-2">
            <label for="form-name"> <i class="fa fa-user prefix grey-text"></i> Nombre: </label>
            <input type="text" id="form-name" class="form-control">
          </div>

          <div class="md-form  mt-2">
            <label for="form-email"> <i class="fa fa-envelope prefix grey-text"></i> Email</label>
            <input type="text" id="form-email" class="form-control">
          </div>

          <div class="md-form  mt-2">
            <label for="form-Subject"><i class="fa fa-tag prefix grey-text"></i> Asunto</label>
            <input type="text" id="form-Subject" class="form-control">
          </div>

          <div class="md-form  mt-2">
            <label for="form-text"><i class="fa fa-pencil prefix grey-text"></i> Mensage</label>
            <textarea id="form-text" class="form-control md-textarea" rows="3"></textarea>
          </div>

          <div class="text-center  mt-2">
            <button class="btn btn-primary">Enviar</button>
          </div>

        </div>
      </div>
      <!-- Form with header -->

    </div>
    <!-- Grid column -->

    <!-- Grid column -->
    <div class="col-lg-7">

      <!--Google map-->
      <div id="map-container-section" class="z-depth-1-half map-container-section mb-4" style="height: 400px">
        <img src="/assets/mapa/mapa.jpg" alt="" srcset="">
      </div>
      <!-- Buttons-->
      <div class="row text-center">
        <div class="col-md-4">
          <a class="btn-floating blue accent-1">
            <i class="fa fa-map-marker"></i>
          </a>
          <p>José Ingenieros 2215</p>
          <p class="mb-md-0">(1852) Burzaco, Bs As</p>
          <p class="mb-md-0">Argentina</p>
        </div>
        <div class="col-md-4">
          <a class="btn-floating blue accent-1">
            <i class="fa fa-phone"></i>
          </a>
          <p>(+ 54 11) 3221-9960 </p>
          <p class="mb-md-0">Lun - Vie, 8:00-17:00 hs</p>
        </div>
        <div class="col-md-4">
          <a class="btn-floating blue accent-1">
            <i class="fa fa-envelope-square"></i>
          </a>
          <p>info@juntasmeyro.com.ar</p>
          <p class="mb-0">ventas@juntasmeyro.com.ar</p>
        </div>
      </div>

    </div>
    <!-- Grid column -->

  </div>
  <!-- Grid row -->

</section>
<!-- Section: Contact v.1 -->

</div>
