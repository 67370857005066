<div class="row">
  <div class="col-sm-9">
    <form [formGroup]="altaForm" (ngSubmit)="onSubmit()">
      <div class="form-row align-items-center">
    
        <div class="row"  (click)="resetMensaje()">
    
          <div class="col-sm-2 my-1" >
            <label class="sr-only" for="cantidad">Cant.</label>
            <input type="number" min="1" oninput="validity.valid||(value='1');" class="form-control" formControlName="cantidad" placeholder="cant.">
    
            <small class="invalid"
              *ngIf="altaForm.controls.cantidad.errors?.required && altaForm.controls.cantidad.touched">
              El Campo es requerido.
            </small>
            <small class="invalid" *ngIf="mensaje ">
              {{ mensaje }}
            </small>
            <!--
                    <small class="invalid"
                      *ngIf="altaForm.controls.year.errors?.minlength && altaForm.controls.year.touched">
                      El año debe tener al menos cuatro caracteres.
                    </small>
                    <small class="invalid"
                      *ngIf="altaForm.controls.year.errors?.maxlength && altaForm.controls.year.touched">
                      El año puede tener cuatro caracteres maxino.
                    </small>
                    <small class="invalid" *ngIf="altaForm.controls.year.errors?.pattern && altaForm.controls.year.touched">
                      El cupo puede tener solo numeros.
                    </small>
                    -->
          </div>
    
          <div class="col-sm-3 my-1">
            <label class="sr-only" for="idArticulo">code</label>
            <input type="text" class="form-control" formControlName="idArticulo" placeholder="99-9999 XX">
          </div>
    
          <div class="col-auto my-1">
            <button type="submit" class="btn btn-success" [disabled]="!altaForm.valid" >Agregar</button>
            <small class="invalid"
              *ngIf="altaForm.controls.idArticulo.errors?.required && altaForm.controls.idArticulo.touched">
              El Campo es requerido.
            </small>
          </div>
        </div>
    
      </div>
    </form>
  </div>
  <!--
  <div class="col-sm-3">
    <div class="col-auto my-1">
      <button type="submit" class="btn btn-success" (click)="lanzaGenerarExcel()" >
        <img class="icon" src="./assets/icons/file-excel-solid.svg" alt="" srcset="">
        Exportar Excel
      </button>
    </div>
  </div>
  -->
</div>