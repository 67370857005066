export class RoleValidator {
    isUsuario(user): boolean {
        return user.role == 3;
    }

    isAdministrador(user): boolean {
        return true;// user;//.role == 2;
    }

    isCliente(user): boolean {
        return true; // user.role == 1;
    }

    isLogin(user): boolean {
        return user;
    }
}
