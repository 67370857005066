<table class="table table-bordered">
    <thead>
        <tr>
            <th>Detalle</th>
            <th>Importe</th>
        </tr>
    </thead>
    <tbody>
        <!--
            <tr>
                <td>Subtotal Lista: </td>
                <td nowrap>
                    <p class="izq">
                        $ {{ this.getSubTotalLista() | number:'1.2-2' }}
                    </p>
                </td>
            </tr>
        -->
        <tr>
            <td>Subtotal Neto: </td>
            <td nowrap>
                <p class="izq">
                    $ {{ this.getSubTotalNeto() | number:'1.2-2' }}
                </p>
            </td>
        </tr>
        <tr *ngIf="currentUser.tieneDtoCateg == '1'">
            <td>dto. Cliente Categorizado : </td>
            <td nowrap style="color:#FF0000">
                <p class="izq">
                    <b> ( $ {{ this.getImporteConDtoCategorizado() | number:'1.2-2' }} ) </b>
                </p>
            </td>
        </tr>
        <tr>
            <td>dto. Pago 30 días : </td>
            <td nowrap style="color:#FF0000">
                <p class="izq">
                    <b> ( $ {{ (this.getSubTotalNeto() - this.getImporteConDtoCategorizado()) *.1 | number:'1.2-2' }} )
                    </b>
                </p>
            </td>
        </tr>
        <tr>
            <td>Total (*): <b> <br> (Sin IVA) </b> </td>
            <td nowrap>
                <h1 class="izq">$ {{ this.getImporteFinal() | number:'1.2-2' }}</h1>
            </td>
        </tr>

    </tbody>
</table>
<div *ngIf="soloDetalle">
<!--
        <button type="button" class="btn btn-success">
        Actualizar Pedido
    </button>
-->
</div>
<div *ngIf="!soloDetalle">
    <button type="button" class="btn btn-success" (click)="cerrarPedido()" [disabled]="pedidoItems.length === 0">
        Finalizar Pedido
    </button>
</div>


<table class="mt-3 table table-bordered">
    <tbody>
        <tr>
            <td>Total Descuentos: </td>
            <td nowrap style="color:#FF0000">
                <b> ( $ {{ this.getImporteConDtoCategorizado() + ((this.getSubTotalNeto() -
                    this.getImporteConDtoCategorizado()) *.1) | number:'1.2-2' }} ) </b>
            </td>
        </tr>
</table>
<p class="info">
    (*) precios sujetos a modificaciones sin aviso previo
</p>