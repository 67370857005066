<div class="container mt-3" *ngIf="isCliente; else loading">
  <div class="row">
    <app-carrito-nav-buttons (verListado)="capCliente($event)"></app-carrito-nav-buttons>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <app-carrito-encabezado [currentUser]="carritoUser"></app-carrito-encabezado>
    </div>
    <div class="col-sm-9">
      <app-carrito-carga-manual (lanzaPedido)="addCarrito($event)" (generarExcel)="exportToExcel()">
      </app-carrito-carga-manual>
    </div>
    <div class="col-sm-9 mt-2">
      <app-carrito-tabla [carritoItems]="pedidoItems" [currentUser]="carritoUser"
        (lanzaItemBorrar)="deleteItemCarrito($event)" (lanzaItemUpdate)="updateItemCarrito($event)">
      </app-carrito-tabla>
      <hr>
      <form [formGroup]="observacionesForm">
        <div class="row">
          <div class="col-sm-12">
            <textarea placeholder="Observaciones..." class="form-control" formControlName="observaciones" rows="4"
              name="observaciones">
              </textarea>
          </div>
        </div>
      </form>
    </div>
    <div class="col-sm-3">
      <app-carrito-ticket (lanzaCerrarPedido)="cerrarPedido($event)" [currentUser]="carritoUser" [descuento]="descuento"
        [pedidoItems]="pedidoItems"></app-carrito-ticket>
    </div>
  </div>
</div>
<ng-template #loading>
  <mat-progress-spinner></mat-progress-spinner>
</ng-template>