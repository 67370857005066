import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
////////////// CLASS....................................................................
import { Cliente } from 'src/app/_class/users/cliente';
import { Pedido } from 'src/app/_class/order/pedido';
import { Descuento } from 'src/app/_class/order/descuento';
////////////// SERVICES.................................................................
import { AuthService } from 'src/app/_auth/auth.service';
import { DescuentosPHPService } from 'src/app/_services/php/descuentosPHP.service';
import { ExportExcelService } from 'src/app/_services/export-excel.service';
import { PedidosItemsPHPService } from 'src/app/_services/php/pedidoItemsPHP.service';
import { PedidoItem } from 'src/app/_class/order/pedidoItem';
import { DescuentoCliente } from 'src/app/_class/order/descuentoCliente';
import { descuentosClientePHPService } from 'src/app/_services/php/descuentosClientePHP.service';
import { PedidosPHPService } from 'src/app/_services/php/pedidosPHP.service';
import { Router } from '@angular/router';
import { Card } from 'src/app/_class/products/card';
import { clientesPHPservice } from 'src/app/_services/php/clientesPHP.service';

@Component({
  selector: 'app-carrito-admin-page',
  templateUrl: './carrito-admin-page.component.html',
  styleUrls: ['./carrito-admin-page.component.scss']
})
export class CarritoAdminPageComponent implements OnInit {

  @Input() carritoUser: Cliente = {};
  @Output() verListado = new EventEmitter();

  public user$: Observable<any> = this.authSvc.afAuth.user;
  public clientes: Cliente[] = [];
  public cliente: Cliente = {};
  public isCliente: boolean = false;
  public pedido: Pedido = {};
  public pedidoItem: PedidoItem = {};
  public pedidoItems: PedidoItem[] = [];
  public descuento: Descuento = {};
  public descuentos: Descuento[] = [];
  public clienteDescuentos: DescuentoCliente[] = [];
  public dataForExcel = [];
  public dataFiltrada = [];
  public cards: Card[] = [];

  constructor(
    private authSvc: AuthService,
    private exportExcelServicelSv: ExportExcelService,
    ///////////////// PHP  //////////////////////////////////////////// 
    private pedidoItemsSv: PedidosItemsPHPService,
    private descuentosSv: DescuentosPHPService,
    private clienteDescuentosSv: descuentosClientePHPService,
    private clientesPHPsv: clientesPHPservice,
    private pedidoSv: PedidosPHPService,
    private router: Router
  ) { }


  public capCliente(event) {
    this.verListado.emit({ cliente: event });
  }


  observacionesForm = new FormGroup({
    observaciones: new FormControl('', [
      Validators.maxLength(100)
    ]),
  })

  ///////////////////////////////////////// user ////////////////////////////////////////  

  public cargarDataFiltrada(event) {
    this.dataFiltrada = event.itemsEnviados;
  }


  public getDescuentoClientes() {
    this.clienteDescuentosSv.gets().subscribe(res => {
      this.clienteDescuentos = res;
    });
  }




  /*public getCurrentUser() {
    this.user$.subscribe((res) => {
      this.clientesSv.getClienteByUid(res.uid).subscribe((cu) => {
        this.currentUser = cu;
      });
    });
  }*/

  //////////////////////////////  carrito   //////////////////////////////////////////////

  public addCarrito(event): any {

    this.pedidoItem.idPedido = -1;
    this.pedidoItem.idCliente = event.cliente.id;
    this.pedidoItem.idArticulo = event.articulo.idArticulo;
    this.pedidoItem.idProducto = event.articulo.idProducto;
    this.pedidoItem.aplicacion = this.cards.find(c => c.idArticulo == event.articulo.idArticulo).aplicacionEspecifica;
    this.pedidoItem.descripcionCorta = event.articulo.descripcionCorta;
    this.pedidoItem.preciolista = event.articulo.precioLista;
    this.pedidoItem.precioneto = event.articulo.precioNeto;

    //  cargo descuento categorizado si es que tiene
    if (event.cliente.tieneDtoCateg == '1') {
      this.clienteDescuentos.forEach(e => {
        if (e.idProducto == event.articulo.idProducto && e.idCliente == event.cliente.id) {
          this.descuentosSv.getById(e.idDescuento).subscribe(res => {
            this.pedidoItem.preciocategorizado = res.coeficiente * event.articulo.precioNeto;
          });
        }
      });
    } else {
      this.pedidoItem.preciocategorizado = 1 * event.articulo.precioNeto;
    }

    // gurarda o update depende si ya está en el carrito
    this.pedidoItemsSv.gets().subscribe(res => {
      if (res.filter(e => e.idArticulo == event.articulo.idArticulo && e.idCliente == this.cliente.id).length) {
        this.pedidoItem.cantidad = res.filter(e => e.idArticulo == event.articulo.idArticulo && e.idPedido == -1 && e.idCliente == this.cliente.id)[0].cantidad + event.cantidad;
        this.pedidoItemsSv.update(res.filter(e => e.idArticulo == event.articulo.idArticulo && e.idPedido == -1 && e.idCliente == this.cliente.id)[0].id, this.pedidoItem).subscribe(res => {
          console.log('UPDATE', res);
          this.getCarritoItems();
        });
      } else {
        this.pedidoItem.cantidad = event.cantidad;
        this.pedidoItemsSv.save(this.pedidoItem).subscribe(res => {
          console.log('SAVE', res);
          this.getCarritoItems();
        });
      }
    });
  }

  exportToExcel() {
    let data = [];

    this.pedidoItemsSv.gets().forEach(element => {
      data.push({
        art: element.articulo.idArticulo,
        cant: element.cantidad
      });
    });

    data.forEach((row: any) => {
      this.dataForExcel.push(Object.values(row))
    })

    let reportData = {
      title: 'Pedido Cta: ' + this.carritoUser.id + ' Fecha: ' + this.getfecha() + ' Expreso: ' + 'Reparto MEYRO',
      data: this.dataForExcel,
      headers: Object.keys(data[0])
    }
    this.exportExcelServicelSv.exportExcel(reportData);
  }

  

  public updateItemCarrito(event) {
    this.pedidoItemsSv.update(event.id, event.itemUpdate);
    console.log('UPDATE');
    this.getCarritoItems();
  }

  public deleteItemCarrito(event) {
    this.pedidoItemsSv.delete(event.id).subscribe(res => {
      console.log(res);
      this.getCarritoItems();
    });
  }

  /*
  public getCarritoItems() {
    console.log(this.carritoUser);
    this.pedidoItemsSv.gets().subscribe(res => {

        this.clientesSv.getClienteByUid(this.carritoUser.uid).subscribe((cu) => {
          this.pedidoItems = res.filter(e => e.idCliente == cu.id && e.idPedido == -1);
        });
      });
  }*/

  public getCarritoItems() {
    this.pedidoItemsSv.gets().subscribe( PedidoItemsRES => {

      this.user$.subscribe((cu) => {
        this.clientesPHPsv.gets().subscribe(res => {

          this.clientes = res;
          this.pedidoItems = res;
          this.cliente = this.clientes.find(cte => cte.uid == cu.uid);
          this.pedidoItems = PedidoItemsRES.filter(e => e.idCliente == this.cliente.idCliente && e.idPedido == -1);

        });
      });
    });
  }

  public cerrarPedido(event) {
    let pedido: Pedido = {
      estado: 2,
      fechaHora: this.getToday(),
      idCliente: this.carritoUser.id,
      idExpreso: 1,
      idSucursal: 1,
      observaciones: "pascual"
    }

    this.pedidoSv.save(pedido).subscribe(res => {
      this.pedido = res;
      console.log(this.pedido);
      this.pedidoItemsSv.updateidpedido(this.pedido).subscribe(res => {
        console.log(res);
        this.getCarritoItems();
      });
    });


  }

  public getPedidoAbiertoCliente() {
    this.pedidoSv.gets().subscribe(res => {
      let pedido = res.filter(e => e.estado == 1 && e.cliente.id == this.carritoUser.id);

      return pedido;
    });
  }

  public getfecha() {
    let fecha = new Date;

    const dia = fecha.getDate().toString();
    const mes = (fecha.getMonth() + 1).toString();
    const anio = fecha.getFullYear().toString();

    return dia + '/' + mes + '/' + anio;
  }

  public getDescuentos() {
    this.descuentosSv.gets().subscribe(res => {
      this.descuentos = res;
    });
  }

  public getToday() {
    let fecha = new Date;

    const dia = fecha.getDate().toString();
    const mes = (fecha.getMonth() + 1).toString();
    const anio = fecha.getFullYear().toString();
    const hora =
      fecha.getHours() < 10
        ? '0' + fecha.getHours().toString()
        : fecha.getHours().toString();
    const minutos =
      fecha.getMinutes() < 10
        ? '0' + fecha.getMinutes().toString()
        : fecha.getMinutes().toString();

    return dia + '/' + mes + '/' + anio + ' ' + hora + ':' + minutos;
  }

  private checkCliente() {
    this.user$.subscribe((cu) => {

      this.clientesPHPsv.gets().subscribe(res => {
        this.clientes = res;
        this.cliente = this.clientes.find(cte => cte.uid == cu.uid);

        this.cliente.estado == 1 ? this.isCliente = true : this.router.navigate(['permisoDenegado']);
      });
    });
  }

  ngOnInit(): void {
    this.checkCliente();
    window.scroll(0, 0);
    this.getCarritoItems();
    this.getDescuentoClientes();
    this.getDescuentos();

    console.log(this.carritoUser);
  }
}