<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="error-template">
                <h1>
                    Oops!</h1>
                <h2>
                    "Acceso denegado"</h2>
                <div class="error-details">
                    Ha ocurrido un error, No tienes permisos para acceder a la página solicitada!
                </div>
                <div class="error-actions">
                    <a routerLink="/home" class="btn btn-primary btn-lg"><span class="glyphicon glyphicon-home"></span>
                        Inicio </a><a routerLink="/about" class="btn btn-default btn-lg"><span class="glyphicon glyphicon-envelope"></span> Solicitar Soporte </a>
                </div>
            </div>
        </div>
    </div>
</div>