<div class="container p-5">
    <div class="card mt-5">
        <div class="card-body">
            <h3 class="card-title text-center">Por favor, ingresá tu email</h3>
            <div class="form-group">
                <input type="email" class="form-control" [formControl]="userEmail" required />
            </div>
            <div class="form-group text-center mt-5">
                <button class="btn btn-primary btn-lg" (click)="onReset()">
                    Resetear contraseña
                </button>
            </div>
        </div>
    </div>
</div>