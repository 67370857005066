import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CarritoItem } from 'src/app/_class/order/carritoItem';
import { Cliente } from 'src/app/_class/users/cliente';
import { PedidoItem } from 'src/app/_class/order/pedidoItem';

@Component({
  selector: 'app-carrito-tabla',
  templateUrl: './carrito-tabla.component.html',
  styleUrls: ['./carrito-tabla.component.css']
})

export class CarritoTablaComponent {

  @Input() carritoItems: PedidoItem[] = [];  // listado de items del carrito
  @Input() currentUser: Cliente =  {};
  
  @Output() lanzaItemBorrar = new EventEmitter();
  @Output() lanzaItemUpdate = new EventEmitter();

  public cant;

  constructor() { }

  public deleteCarritoItem(item: CarritoItem) {
    this.lanzaItemBorrar.emit(item);
  }

  public sendCant(event) {
    this.cant = event.target.value;
  }

  public updateCarritoItem(item: CarritoItem) {
    item.cantidad = this.cant;
    this.lanzaItemUpdate.emit(
      {
        itemUpdate: item
      });
  }
}
