<ul class="navbar-nav me-auto mb-2 mb-lg-0">
    <li class="nav-item">
        <a class="nav-link active" aria-current="page" routerLink="/home">Home</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" routerLink="/catalogo">Catalogo</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" routerLink="/carrito">Carrito</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" routerLink="/admin">Web Admin</a>
    </li>

</ul>