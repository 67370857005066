import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Cliente } from 'src/app/_class/users/cliente';
import { Descuento } from 'src/app/_class/order/descuento';
import { DescuentoCliente } from 'src/app/_class/order/descuentoCliente';
import { dtosCliente } from 'src/app/_class/order/dtosCliente';
import { Producto } from 'src/app/_class/products/producto';

@Component({
  selector: 'app-usuarios-detalle',
  templateUrl: './usuarios-detalle.component.html',
  styleUrls: ['./usuarios-detalle.component.scss']
})
export class UsuariosDetalleComponent implements OnInit {

  @Output() lanzaDescuentoCategorizado = new EventEmitter();
  @Output() lanzaClienteTieneDtoCateg = new EventEmitter();
  @Output() changeView = new EventEmitter();

  @Input() viewUser: Cliente = {};
  @Input() productos: Producto[] = [];
  @Input() descuentos: Descuento[] = [];
  @Input() descuentosCliente: DescuentoCliente[] = [];

  public descuento: Descuento = {};
  public Dto: dtosCliente = {};
  public dtosCliente: dtosCliente[] = [];
  public idDescuento: number = 0;
  public isAdmin: boolean = false;

  constructor() { }

  public cambiarVista() {
    this.changeView.emit();
  }


  public updateClienteDtoCategorizado(event) {
    this.lanzaDescuentoCategorizado.emit( event );
  }

  public updateTieneDtoCategorizado(){
    this.lanzaClienteTieneDtoCateg.emit( this.viewUser );
  }

  ngOnInit(): void {
    window.scroll(0, 0);
  }
}
