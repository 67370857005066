import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { PerfilPageComponent } from './pages/perfil-page/perfil-page.component';
import { PerfilRoutingModule } from './perfil-routing.module';
import { ClienteDescuentosComponent } from '../../../../web/admin/components/cliente-descuentos/cliente-descuentos.component';
import { DescurntosProductoDetalleComponent } from '../../../../web/admin/components/descurntos-producto-detalle/descurntos-producto-detalle.component';

@NgModule({
  declarations: [
    PerfilPageComponent,
    ClienteDescuentosComponent,
    DescurntosProductoDetalleComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PerfilRoutingModule
  ],
  exports: [PerfilPageComponent, ClienteDescuentosComponent],
  providers: [],
})
export class PerfilModule { }
