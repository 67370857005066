import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/_auth/auth.service';
import { Cliente } from 'src/app/_class/users/cliente';
import { Pedido, EEstadoPedido } from 'src/app/_class/order/pedido';
import { PedidoItem } from 'src/app/_class/order/pedidoItem';
import { ExportExcelService } from 'src/app/_services/export-excel.service';
import { clientesPHPservice } from 'src/app/_services/php/clientesPHP.service';
import { EmpleadosPHPService } from 'src/app/_services/php/empleadossPHP.service';

@Component({
  selector: 'app-pedidos-detalle',
  templateUrl: './pedidos-detalle.component.html',
  styleUrls: ['./pedidos-detalle.component.css']
})
export class PedidosDetalleComponent implements OnInit {

  @Output() throwEstadoUpdate = new EventEmitter();
  @Input() pedido: Pedido = {};
  @Input() pedidoItems: PedidoItem[] = [];
  @Input() currentUser: Cliente = {};
  @Input() isAdmin: boolean = false;
  @Output() throwVolverAPedido = new EventEmitter();

  public razonSocial: string = '';
  public user$: Observable<any> = this.authSvc.afAuth.user;
  public isCliente: boolean = false;
  public clientes: Cliente[] = [];
  public dataForExcel = [];
  public isDataLoaded: boolean = false;
  public estadoDetalle = '';
  public clase: string;
  public EEstadoPedido = EEstadoPedido; // Hacer el enum accesible en la plantilla

  constructor(
    private authSvc: AuthService,
    private router: Router,
    private empleadosPHPsv: EmpleadosPHPService,
    private clientesPHPsv: clientesPHPservice,
    private exportExcelServicelSv: ExportExcelService
  ) { }

  public actualizarEstado(estado: EEstadoPedido, pedido: Pedido) {
    switch (estado) {
      case EEstadoPedido.leido:
        pedido.estado = EEstadoPedido.leido;
        this.estadoDetalle = 'Leído';
        break;
      case EEstadoPedido.noleido:
        pedido.estado = EEstadoPedido.noleido;
        this.estadoDetalle = 'No Leído';
        break;
      case EEstadoPedido.anulado:
        pedido.estado = EEstadoPedido.anulado;
        this.estadoDetalle = 'Anulado';
        break;
      case EEstadoPedido.entregado:
        pedido.estado = EEstadoPedido.entregado;
        this.estadoDetalle = 'Entregado';
        break;
    }
    this.throwEstadoUpdate.emit(pedido);
    this.claseButton(estado);
  }

  volverAPedidos() {
    this.throwVolverAPedido.emit();
  }

  public claseButton(estado) {
    switch (estado) {
      case '1':
        this.clase = 'badge rounded-pill bg-success';
        this.estadoDetalle = 'Leído';
        break;
      case '2':
        this.clase = 'badge rounded-pill bg-info';
        this.estadoDetalle = 'No Leído';
        break;
      case '3':
        this.clase = 'badge rounded-pill bg-danger';
        this.estadoDetalle = 'Anulado';
        break;
    }
  }

  exportToExcel() {
    let data = [];

    // Ordenar los pedidoItems por idArticulo en orden decreciente
    this.pedidoItems.sort((b, a) => b.idArticulo.localeCompare(a.idArticulo));

    // Preparar datos
    this.pedidoItems.forEach(element => {
      data.push({
        art: element.idArticulo,
        cant: element.cantidad,
        descripcion: element.descripcionCorta,
        precio_neto: element.precioneto,
        subtotal: element.cantidad * element.precioneto
      });
    });

    this.dataForExcel = data.map(row => Object.values(row));

    let reportData = {
      pedidoNro: this.pedido.id,
      idCliente: this.pedido.idCliente,
      razonSocial: this.getRazonSocial(this.pedido.idCliente),
      fechaHora: this.getfecha(),
      data: this.dataForExcel,
      headers: Object.keys(data[0])
    };

    this.exportExcelServicelSv.exportExcel(reportData);
  }

  public getfecha() {
    let fecha = new Date;

    const dia = fecha.getDate().toString();
    const mes = (fecha.getMonth() + 1).toString();
    const anio = fecha.getFullYear().toString();

    return `${dia}/${mes}/${anio}`;
  }

  getRazonSocial(idCliente: string): string | undefined {
    const cliente = this.clientes.find(cliente => cliente.idCliente === idCliente);
    this.razonSocial = cliente ? cliente.razonSocial : 'Razón Social No Encontrada';
    return this.razonSocial;
  }

  private checkCliente() {
    this.user$.subscribe((cu) => {
      this.clientesPHPsv.gets().subscribe(res => {

        this.razonSocial = this.getRazonSocial(this.pedido.idCliente);
        this.isDataLoaded = true;

        this.clientes = res;
        this.currentUser = this.clientes.find(cte => cte.uid == cu.uid);

        if(this.currentUser && this.currentUser?.estado == 1) {
          this.isCliente = true;
        } else {
          this.empleadosPHPsv.gets().subscribe( emp => {
            this.currentUser = emp.find(e => e.uid == cu.uid);
          })
        }

        if (this.currentUser.estado == 1) {
          this.isCliente = true;
        } else {
         this.router.navigate(['permisoDenegado']);
        }
      });
    });
  }

  ngOnInit(): void {
    this.checkCliente();
    window.scrollTo(0, 0);
    this.claseButton(this.pedido.estado);
  }
}
