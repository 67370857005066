import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Cliente } from 'src/app/_class/users/cliente';
import { Descuento } from 'src/app/_class/order/descuento';
import { DescuentoCliente } from 'src/app/_class/order/descuentoCliente';
import { Producto } from 'src/app/_class/products/producto';

@Component({
  selector: 'app-descurntos-producto-detalle',
  templateUrl: './descurntos-producto-detalle.component.html',
  styleUrls: ['./descurntos-producto-detalle.component.scss']
})
export class DescurntosProductoDetalleComponent implements OnInit {

  @Output() lanzaDescuentoCategorizado = new EventEmitter();

  @Input() viewUser: Cliente = {};
  @Input() producto: Producto = {};
  @Input() descuentos: Descuento[] = [];
  @Input() descuentosCliente: DescuentoCliente[] = [];

  public cliente: Cliente = {};
  public descuento: Descuento = {};
  public descuentoCat: Descuento = {};
  public change = true;

  constructor() { }

  public getDtoProducto(producto: Producto): Descuento | void {
    this.descuentosCliente.forEach(e => {
      if ( e.idProducto == producto.id_producto ) {
        return this.getDescuentoByID(e.id);
      }
    });
  }

  public getDescuentoByID(id) {
    this.descuentos.forEach( e => {
      if( e.id == id ){
        this.descuentoCat = e;
        return e.coeficiente;
      } else {
        return {};
      }
    });
    console.log('654645564645564645645');
  }

  public updateClienteDtoCategorizado(event) {
    this.lanzaDescuentoCategorizado.emit({ 
      idDescuento: event.target.value,
      producto: this.producto,
      viewUser: this.viewUser
    });
    this.getDescuentoByID(this.producto.idDescuento);
  }

  getDto() {
    this.descuentos.forEach(element => {
      if (element.id == this.producto.idDescuento) {
        this.descuento = element;
      }
    });
  }

  public update(){
    this.change = !this.change;
  }

  ngOnInit(): void {
    // this.getDtoProducto();
    this.getDto();
  }
}
