<div class="container">
  <nav data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
    aria-expanded="false" aria-label="Toggle navigation" class="navbar navbar-expand-lg fixed-top navbar-light bg">
    <div class="container container-fluid">

      <a class="navbar-brand" routerLink="/home">
        <img src="/assets/logo/logo.png" alt="">
      </a>

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- MENU CLIENTES -->

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" routerLink="/home">Inicio</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/nosotros">Nosotros</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/dtotecnico">dto. Técnico</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/catalogo">Catalogo</a>
          </li>
          <li *ngIf="isEmpleado">
            <a class="nav-link" routerLink="/admin">Admin</a>
          </li>
          <li *ngIf="isCliente" class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              Pedidos
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" routerLink="/pedidos">Mis Pedidos</a>
              <a class="dropdown-item" routerLink="/carrito">Carrito</a>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/contacto">Contacto</a>
          </li>

        </ul>
        <ul class="nav navbar-nav navbar-right" data-bs-toggle="collapse" *ngIf="user$ | async as user; else login">
          <li class="nav-item dropdown">

            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              <!--
                            <i class="fa fa-shopping-cart p-2"></i>              <span class="badge bg-black"> {{ cantItemsCarrito }}</span>

              -->
              {{ user.email }}
            </a>
            <ul data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" class="dropdown-menu"
              aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" routerLink="/archivos">Archivos</a></li>
              <li><a class="dropdown-item" routerLink="/pedidos">Mis Pedidos</a></li>
              <li><a class="dropdown-item" routerLink="/carrito">Carrito</a></li>
              <hr class="dropdown-divider">

              <li><a class="dropdown-item" href="" (click)="onLogout()">Cerrar Sesión</a></li>
            </ul>
          </li>
        </ul>
        <ng-template #login>
          <ul class="nav navbar-nav navbar-right">
            <li class="nav-item">
              <a class="nav-link" routerLink="/registro">Creá tu cuenta</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/login">Ingresá</a>
            </li>

          </ul>
        </ng-template>
      </div>
    </div>
  </nav>

</div>