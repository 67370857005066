<div *ngIf="!viewCarrito">
  <div class="row mt-3" *ngIf="isEmpleado; else loading">
    <!-- TITLE -->
    <div class="row">
      <div class="col-sm-12">
        <h4 class="fs-1">Clientes</h4>
      </div>
    </div>
    <div class="col-sm-12" *ngIf="viewChange">
      <app-usuarios-listado [clientes]="clientes" (verCliente)="verDetalleCliente($event)" 
      (carritoCliente)="comprarCarritoCliente($event)"  (clienteUpdate)="updateCliente($event)" >
      </app-usuarios-listado>
    </div>

    <div class="col-sm-12" *ngIf="!viewChange">
      <app-usuarios-detalle (lanzaDescuentoCategorizado)="updateDescuentoCliente($event)"
        (lanzaClienteTieneDtoCateg)="updateTieneDtoCateg($event)" [viewUser]="clienteViewer" [descuentos]="descuentos"
        [productos]="productos" [descuentosCliente]="descuentosCliente" (changeView)="changeView()">
      </app-usuarios-detalle>
    </div>
  </div>
</div>

<div *ngIf="viewCarrito">
  <app-carrito-admin-page [carritoUser]="clienteCarrito" (verListado)="comprarCarritoCliente($event)">
  </app-carrito-admin-page>
</div>

<ng-template #loading>
  ...
</ng-template>