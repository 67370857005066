<div class="container mt-3">

  <ng-template matStepLabel>Tus Datos</ng-template>

  <h2>Numero de Cuenta : {{ currentUser.idCliente }}</h2>

  <div class="row">

    <!-- ////////////////////////////  RAZON SOCIAL //////////////////////////////////////////////// -->
    <div class="col-sm-6 p-2">
      <label for="year">Razon Social :</label>
      <input type="text" class="form-control" [value]="currentUser.razonSocial" />
    </div>

    <!-- ////////////////////////////  CALLE Y NRO //////////////////////////////////////////////// -->
    <div class="col-sm-6 p-2">
      <label for="year">Calle y Numero :</label>
      <input type="text" class="form-control" [value]="currentUser.calleAndNumero" />
    </div>

    <!-- ////////////////////////////  LOCALIDAD //////////////////////////////////////////////// -->
    <div class="col-sm-9 p-2">
      <label for="year">Localidad :</label>
      <input type="text" class="form-control" [value]="currentUser.localidad" />
    </div>

    <!-- ////////////////////////////  CP //////////////////////////////////////////////// -->
    <div class="col-sm-3 p-2">
      <label for="year">Codigo Postal :</label>
      <input type="text" class="form-control" [value]="currentUser.cp" />
    </div>

    <!-- ////////////////////////////  PROVINCIA //////////////////////////////////////////////// -->
    <div class="col-sm-7 p-2">
      <label for="year">Provincia :</label>
      <input type="text" class="form-control" [value]="currentUser.provincia" />
    </div>

    <!-- ////////////////////////////  TELEFONO //////////////////////////////////////////////// -->
    <div class="col-sm-5 p-2">
      <label for="year">Telefono :</label>
      <input type="text" class="form-control" [value]="currentUser.telefonoLinea" />
    </div>

    <!-- ////////////////////////////  COMPRADOR //////////////////////////////////////////////// -->
    <div class="col-sm-6 p-2">
      <label for="year">Responsable de Compras :</label>
      <input type="text" class="form-control" [value]="currentUser.comprador" />
    </div>

    <!-- ////////////////////////////  CELULAR //////////////////////////////////////////////// -->
    <div class="col-sm-6 p-2">
      <label for="year">Telefono Celular :</label>
      <input type="text" class="form-control" [value]="currentUser.telefonoCelular" />
    </div>

    <!-- ////////////////////////////  MAIL //////////////////////////////////////////////// -->
    <div class="col-sm-6 p-2">
      <label for="year">email :</label>
      <input type="text" class="form-control" [value]="currentUser.email" />
    </div>

    <app-cliente-descuentos [productos]="productos" [descuentosCliente]="descuentosCliente" [viewUser]="currentUser" [descuentos]="descuentos"></app-cliente-descuentos>

  </div>


