<!-- Footer -->
<footer class="text-center text-lg-start bg-light text-muted">
  <!-- Section: Social media -->
  <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom bg_rojo">
    <div class="container">
      <div class="row">
        <!-- Left -->
        <!-- Grid column -->
        <div class="col text-left text-md-left mb-4 mb-md-0">
          <h6 class="conectate mb-0"><b>¡Conéctate con nuestras redes sociales!</b></h6>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col redes text-right text-md-right">

          <!-- Facebook -->
          <div>
            <a href="https://www.facebook.com/JuntasMeyro" target="_blank"
              class="redes p-2 fs-18 cl7 hov-cl1 trans-04 m-r-16">
              <i class="fa fa-facebook fa-lg"></i>
            </a>
            <a href="https://twitter.com/JuntasMeyro" target="_blank" class="p-2 fs-18 cl7 hov-cl1 trans-04 m-r-16">
              <i class="redes fa fa-twitter fa-lg"></i>
            </a>
            <a href="https://www.linkedin.com/company/juntasmeyro" target="_blank"
              class="redes p-2 fs-18 cl7 hov-cl1 trans-04 m-r-16">
              <i class="fa fa-linkedin fa-lg"></i>
            </a>
            <a href="https://www.youtube.com/channel/UC0nGAJ75SpZvImM6GU98xDA" target="_blank"
              class="redes p-2 fs-18 cl7 hov-cl1 trans-04 m-r-16">
              <i class="fa fa-youtube fa-lg"></i>
            </a>
            <a href="https://www.instagram.com/juntasmeyro/" target="_blank"
              class="redes p-2 fs-18 cl7 hov-cl1 trans-04 m-r-16">
              <i class="fa fa-instagram fa-lg"></i>
            </a>
          </div>
        </div>
        <!-- Right -->
      </div>
    </div>
  </section>
  <!-- Section: Social media -->

  <!-- Section: Links  -->
  <section class="">
    <div class="container text-left text-md-left mt-5">

      <!-- Grid row -->
      <div class="row">

        <!-- Grid column -->
        <div class="col-sm-3 float-left">

          <!-- Content -->
          <h6 class="text-uppercase font-weight-bold"><b>Junar S.A.</b></h6>
          <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
          <p><i class="fa fa-home mr-3"></i> José Ingenieros 2215</p>
          <p>(1852) Parque Industrial Burzaco</p>
          <p>Alte. Brown, Pcia de Buenos Aires</p>
          <p>Argentina</p>

        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">

          <!-- Links -->
          <h6 class="text-uppercase font-weight-bold">Productos</h6>
          <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
          <p>
            <a href="#!">Juntas</a>
          </p>
          <p>
            <a href="#!">Bulones</a>
          </p>
          <p>
            <a href="#!">Retenes</a>
          </p>

        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">

          <!-- Links -->
          <h6 class="text-uppercase font-weight-bold">Links</h6>
          <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
          <p>
            <a routerLink="/catalogo">Catalogo </a>
          </p>
          <p>
            <a routerLink="/dtotecnico">Dto. Técnico</a>
          </p>
          <p>
            <a routerLink="/productos">Productos</a>
          </p>
          <p>
            <a routerLink="/ayuda">Ayuda</a>
          </p>

        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-5 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">

          <!-- Links -->
          <h6 class="text-uppercase font-weight-bold">Contacto</h6>
          <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
          <p><i class="fa fa-envelope mr-3"></i> ventas@juntasmeyro.com.ar</p>
          <p><i class="fa fa-phone mr-3"></i> (+ 54 11) 3221-9960</p>
          <p><i class="fa fa-print mr-3"></i> Lineas Rotativas</p>

        </div>
        <!-- Grid column -->

      </div>
      <!-- Grid row -->

    </div>
  </section>
  <!-- Section: Links  -->

  <!-- Copyright -->
  <div class="text-center p-4" style="background-color: rgba(0, 0, 0, 0.05);">
    © 2022 Copyright:
    <a href="https://danieldebrito.com.ar/"> JUNAR S.A.</a>
  </div>
  <!-- Copyright -->
</footer>
<!-- Footer -->