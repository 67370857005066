import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { LayoutRoutingModule } from './layout-routing.module';
import { MaterialModule } from '../material.module';
import { AboutComponent } from './pages/about/about.component';
import { ContactFormComponent } from './pages/contact-form/contact-form.component';
import { NavbarAdminComponent } from './components/navbar-admin/navbar-admin.component';
import { NavbarClienteComponent } from './components/navbar-cliente/navbar-cliente.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';


@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    PageNotFoundComponent,
    AboutComponent,
    ContactFormComponent,
    NavbarAdminComponent,
    NavbarClienteComponent,
  ],
  imports: [
    CommonModule,
    LayoutRoutingModule,
    MaterialModule,
    MDBBootstrapModule
  ],
  exports: [
    NavbarComponent,
    FooterComponent,
    PageNotFoundComponent,
    LayoutRoutingModule
  ],
})
export class LayoutModule { }
