import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { AuthService } from 'src/app/_auth/auth.service';
import { Router } from '@angular/router';

// class
import { User } from 'src/app/_auth/models/user.interface';
import { Cliente } from 'src/app/_class/users/cliente';
import { Roles, Estado } from 'src/app/_class/users/usuario';
import { clientesPHPservice } from 'src/app/_services/php/clientesPHP.service';

//services

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent {

  public cliente: Cliente = {};
  public bottonHabilitado = false;
  public cuitCorrecto = false;

  //  stepper ////////////////////////////
  public isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  ////////////////////////////////////////

  registerForm = new FormGroup({
    id: new FormControl(''),
    idCliente: new FormControl(''),
    tieneDtoCateg: new FormControl(''),
    uid: new FormControl(''),
    emailVerified: new FormControl(''),
    estado: new FormControl(''),
    cuit: new FormControl('', [
      // Validators.required,
      Validators.pattern('([0-9]{11}|[0-9]{2}-[0-9]{8}-[0-9]{1})'),
    ]),
    razonSocial: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(100),
      // Validators.pattern('[a-zA-Z ]{2,41}'),
    ]),
    calleAndNumero: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(100),
      // Validators.pattern('[a-zA-Z ]{2,41}'),
    ]),
    localidad: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(100),
      // Validators.pattern('[a-zA-Z ]{2,41}'),
    ]),
    cp: new FormControl('', [
      Validators.required,
      Validators.minLength(4),
      Validators.maxLength(8),
      // Validators.pattern('^[0-9]*$'),
    ]),
    provincia: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(40),
      // Validators.pattern('^[a-zA-Z ]+$'),
    ]),

    telefonoLinea: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(100),
      // Validators.pattern('/^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/'),
    ]),
    comprador: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(100),
      // Validators.pattern('[a-zA-Z]{2,100}'),
    ]),
    telefonoCelular: new FormControl('', [
      // Validators.required,
      // Validators.minLength(2),
      // Validators.maxLength(40),
      // Validators.pattern('[a-zA-Z ]{2,41}'),
    ]),

    email: new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.minLength(6),
      Validators.maxLength(100),
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(20),
    ]),
    role: new FormControl('', []),
    updated_at: new FormControl('', []),
    created_at: new FormControl('', [])
  });

  constructor(
    private authSvc: AuthService,
    private router: Router,
    private clientesPHPsv: clientesPHPservice
  ) {
    this.isLinear = false;
  }

  async traerClienteByCuit() {
    this.bottonHabilitado = false;
    if (this.registerForm.getRawValue().cuit.length === 13) {

      this.clientesPHPsv.gets().subscribe(res => {

        this.cliente = res.find(c => c.cuit == this.registerForm.getRawValue().cuit);

        if (this.cliente.id != '') {

          this.bottonHabilitado = true;
          this.cuitCorrecto = true;

          this.cliente.estado = Estado.desabilitado;
          this.cliente.tieneDtoCateg = '0';
          this.cliente.uid = '';
          this.cliente.emailVerified = false;
          this.cliente.role = Roles.cliente;
          this.registerForm.setValue(this.cliente)

          console.log('cuit correcto');
        } else {
          this.cuitCorrecto = false;
        }
      });
    } else {
      console.log('cuit incorrecto');
    }
  }

  async onRegister() {
    if (this.registerForm.invalid) {
      console.log('formulario con error');
    } else {
      try {
        this.cliente = this.registerForm.getRawValue();
        const user = await this.authSvc.register(this.cliente);

        this.clientesPHPsv.update(this.cliente.id, this.cliente).subscribe((res) => {
          return res;
        });

        if (user) { this.checkUserIsVerified(user); }

      } catch (error) {
        console.log(error);
      }
    }
  }

  private checkUserIsVerified(user: User) {
    if (user && user.emailVerified) {
      this.router.navigate(['/home']);
    } else if (user) {
      this.router.navigate(['/verification-email']);
    } else {
      this.router.navigate(['/register']);
    }
  }

  public resetError() {
    // this.registerForm.reset();
  }
  public resetForm() {
    this.registerForm.reset();
  }
}
