import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BasePHPService {

  public urlBasePHP: string;

  constructor(public http: HttpClient) {

    // LOCAL php
    // this.urlBasePHP = 'http://127.0.0.1:8000/api';
    // vn power
    this.urlBasePHP = 'https://www.apiphp.juntasmeyro.com.ar/public/api';
  }


  public getURL(){
    return this.urlBasePHP;
  }

  public httpGetP( url: string) {
    return this.http
    .get( this.urlBasePHP + url )
    .toPromise()
    .then( this.extractData )
    .catch( this.handleError );
  }

  public httpDeleteP( url: string) {
    return this.http
    .delete( this.urlBasePHP + url )
    .toPromise()
    .then( this.extractData )
    .catch( this.handleError );
  }

  public httpPostP( url: string, request: object) {
    return this.http.post( this.urlBasePHP + url, request).toPromise();
  }

  public httpGetO<T>( url: string) {
    return this.http.get<T>( this.urlBasePHP + url );
  }

  private extractData( res: Response ) {
    return res.json() || {};
  }

  private handleError( error: Response | any ) {
    return error;
  }
}
