import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'
import { UsuariosListadoComponent } from './usuarios-listado.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MaterialModule } from 'src/app/material.module';

@NgModule({
  declarations: [
    UsuariosListadoComponent
  ],
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    MaterialModule
  ],
  exports: [
    UsuariosListadoComponent
  ]
})
export class UsuariosListadoModule { }
