import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { AuthService } from './auth.service';
import { AuthRoutingModule } from './auth-routing.module';

import { LoginModule } from './pages/auth/login/login.module';
import { ForgotPasswordModule } from './pages/auth/forgot-password/forgot-password.module';
import { RegisterModule } from './pages/auth/register/register.module';
import { SendEmailModule } from './pages/auth/send-email/send-email.module';
import { UserPassIncorrectoModule } from './pages/auth/user-pass-incorrecto/user-pass-incorrecto.module';
import { PermisoDenegadoModule } from './pages/auth/permiso-denegado/permiso-denegado.module';
import { UsuariosModule } from '../web/admin/pages/usuarios/usuarios.module';

@NgModule({
  declarations: [  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LoginModule,
    ForgotPasswordModule,
    RegisterModule,
    SendEmailModule,
    UserPassIncorrectoModule,
    PermisoDenegadoModule,
    AuthRoutingModule,
    UsuariosModule
  ],
  exports: [],
  providers: [AuthService],
})
export class AuthModule {}
