import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/_auth/auth.service';
//// classes
import { Cliente } from 'src/app/_class/users/cliente';
import { Descuento } from 'src/app/_class/order/descuento';
import { DescuentoCliente } from 'src/app/_class/order/descuentoCliente';
import { Producto } from 'src/app/_class/products/producto';
///// services
// import { ClientesService } from 'src/app/_services/clientes.service';
import { descuentosClientePHPService } from 'src/app/_services/php/descuentosClientePHP.service';
import { DescuentosPHPService } from 'src/app/_services/php/descuentosPHP.service';
import { ProductosPHPService } from 'src/app/_services/php/productosPHP.service';
import { EmpleadosPHPService } from 'src/app/_services/php/empleadossPHP.service';
import { Empleado } from 'src/app/_class/users/empleado';
import { Estado } from 'src/app/_class/users/usuario';
import { clientesPHPservice } from 'src/app/_services/php/clientesPHP.service';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent implements OnInit {

  public user$: Observable<any> = this.authSvc.afAuth.user;
  public currentUser: Cliente = {};

  public isAdmin = false;

  public viewChange = true;
  public viewCarrito = false;

  public clienteViewer: Cliente = {};
  public clienteCarrito: Cliente = {};

  public isEmpleado: boolean = false;
  public empleados: Empleado[] = [];

  public isCliente: boolean = false;
  public clientes: Cliente[] = [];

  public descuentos: Descuento[] = [];
  public productos: Producto[] = [];
  public descuentosCliente: DescuentoCliente[] = [];

  constructor(
    private authSvc: AuthService,
    private clientesPHP: clientesPHPservice,
    private descuentosPHPSv: DescuentosPHPService,
    private productosPHPSv: ProductosPHPService,
    private descuentoClienteSv: descuentosClientePHPService,
    private empleadoSv: EmpleadosPHPService,
    // private clientesSv: ClientesService
  ) { }

  public verDetalleCliente(event) {
    this.changeView();
    this.clienteViewer = event.cliente;
  }

  public comprarCarritoCliente(event) {
    this.carritoView();
    this.clienteCarrito = event;
    console.log(this.clienteCarrito, event);
  }

  public carritoView() {
    this.viewCarrito = !this.viewCarrito;
  }

  public changeView() {
    this.viewChange = !this.viewChange;
  }

  public getDescuentos() {
    this.descuentosPHPSv.gets().subscribe(res => {
      this.descuentos = res;
    });
  }

  public getProdcutos() {
    this.productosPHPSv.gets().subscribe(res => {
      this.productos = res.filter(e => e.activo == 1);
      console.log(res);
      // this.productos = res;
    });
  }

  public getDescuentosCliente() {
    this.descuentoClienteSv.gets().subscribe(res => {
      this.descuentosCliente = res;
    });
  }

  public updateCliente(event) {
    this.clientesPHP.update(event.id, event).subscribe(res => {
      this.clientesPHP.gets().subscribe( ctes => {
        this.clientes = ctes;
        return res;
      });
    });
  }

  public updateTieneDtoCateg(item: Cliente) {

    if (item.tieneDtoCateg == '0') {
      item.tieneDtoCateg = '1';
    } else {
      item.tieneDtoCateg = '0';
    }

    this.clientesPHP.update( item.id, item ).subscribe( res => {
      return res;
    });
  }

  public updateDescuentoCliente(event) {

    let elementUpdateCreate: DescuentoCliente = {};

    elementUpdateCreate.idCliente = event.viewUser.id;
    elementUpdateCreate.idDescuento = event.idDescuento;
    elementUpdateCreate.idProducto = event.producto.id_producto;



    if (this.descuentosCliente.filter(e => e.idCliente == event.viewUser.id && e.idProducto == event.producto.id_producto).length) {
      this.descuentoClienteSv.update(this.descuentosCliente.filter(e => e.idCliente == event.viewUser.id && e.idProducto == event.producto.id_producto)[0].id, elementUpdateCreate).subscribe(res => {
        console.log('UPDATE', res);
        this.getDescuentosCliente();
      });
    } else {

      this.descuentoClienteSv.save(elementUpdateCreate).subscribe(res => {
        console.log('SAVE', res);
        this.getDescuentosCliente();
      });
    }
  }


  public getClientes(){
    this.clientesPHP.gets().subscribe( res => {
      this.clientes = res;
    } );
  }


  private checkIsEmpleado() {
    this.user$.subscribe((current) => {
      this.empleadoSv.gets().subscribe(res => {
        this.empleados = res;
        if (this.empleados.find(e => e.uid == current.uid && e.estado == Estado.habilitado)) {
          this.isEmpleado = true;
        } else {
          this.isEmpleado = false;
        }
      });
    });
  }

/*
  private checkCliente() {
    this.user$.subscribe((current) => {
      this.clientesSv.getClientes().subscribe(res => {
        this.clientes = res;
        if (this.clientes.find(e => e.uid == current.uid && e.estado == Estado.habilitado)) {
          this.isCliente = true;
        } else {
          this.isCliente = false;
        }
      });
    });
  }
  */

  private checkCliente() {
    this.user$.subscribe((cu) => {

      this.clientesPHP.gets().subscribe(res => {
        this.clientes = res;
        this.currentUser = this.clientes.find(cte => cte.uid == cu.uid && cte.estado == Estado.habilitado);

        // this.currentUser.estado == 1 ? this.isCliente = true : this.router.navigate(['permisoDenegado']);
      });
    });
  }

  ngOnInit(): void {
    this.checkIsEmpleado();
    this.checkCliente();
    this.getDescuentos();
    this.getProdcutos();
    this.getDescuentosCliente();
  }
}
