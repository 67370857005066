import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UsuariosDetalleComponent } from './usuarios-detalle.component';
import { NgxPaginationModule } from 'ngx-pagination';

import { PerfilModule } from 'src/app/_auth/pages/auth/perfil/perfil.module';


@NgModule({
  declarations: [
    UsuariosDetalleComponent
  ],
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    PerfilModule
  ], exports: [
    UsuariosDetalleComponent
  ]
})
export class UsuariosDetalleModule { }
