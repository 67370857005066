<div class="row">
  <div class="table-responsive scrolltable">
    <table class="table caption-top">
      <thead class="table-light">
        <tr class="cent">
          <th colspan="2" class="cant">Cantidad</th>
          <th scope="col">Codigo</th>
          <th scope="col">Aplicación</th>
          <th scope="col">P.Lista</th>

          <th scope="col">
            <div [ngSwitch]="currentUser.tieneDtoCateg">
              <!-- CLIENTE SIN DESCUENTO 
              <div *ngSwitchCase="0">P. Base</div>-->
              <!-- CLIENTE CON DESCUENTO -->
              <div *ngSwitchCase="1">P. Neto</div>
            </div>
          </th>
          <th nowrap scope="col">
            <div [ngSwitch]="currentUser.tieneDtoCateg">
              <!-- CLIENTE SIN DESCUENTO -->
              <div *ngSwitchCase="0">P. Neto</div>
              <!-- CLIENTE CON DESCUENTO -->
              <div *ngSwitchCase="1">Neto c/Descuento</div>
            </div>
          </th>


          <th scope="col">Sub Total</th>
          <th>Quitar</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of carritoItems">

          <td class="cant">
            <!-- CANTIDAD -->
            <input style="width: 70px;" type="number" class="form-control" (change)="sendCant($event)" name="cant"
              value="{{ item.cantidad }}">
          </td>
          <td>
            <button type="button" class="btn btn-success" (click)="updateCarritoItem(item)">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-check-square-fill" viewBox="0 0 16 16">
                <path
                  d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
              </svg>
            </button>
          </td>

          <td nowrap class="cent">
            <!-- CODIGO -->
            {{ item.idArticulo }}
          </td>

          <td nowrap class="izq">
            <!-- APLICACION -->
            {{ item.aplicacion }} <br> {{ item.descripcionCorta }}
          </td>

          <td nowrap class="precio">
            <!-- PRECIO DE LISTA -->
            $ {{ item.preciolista | number:'1.2-2' }}
          </td>

          <td nowrap class="precio">
            <div [ngSwitch]="currentUser.tieneDtoCateg">
              <!-- CLIENTE SIN DESCUENTO => MONTO DTO BASE 
              <div *ngSwitchCase="0">
                <div class="precio dto">
                  $ ({{ item.preciolista - item.precioneto | number:'1.2-2' }})
                </div>
                <p class="info">
                  ( dto.{{ 100 - ( item.precioneto / item.preciolista ) * 100 | number: '1.0-0' }}%)
                </p>
              </div>-->

              <!-- CLIENTE CON DESCUENTO => PRECIO NETO -->
              <div *ngSwitchCase="1">
                $ {{ item.precioneto | number:'1.2-2' }} <br>
                <div *ngIf=" item.precioneto > item.preciolista">
                  <p class="info">
                    ( Rec.{{ 100 - ( item.precioneto / item.preciolista ) * 100 | number: '1.0-0' }}%)
                  </p>
                </div>
                <div *ngIf=" item.precioneto < item.preciolista">
                  <p class="info">
                    ( dto.{{ 100 - ( item.precioneto / item.preciolista ) * 100 | number: '1.0-0' }}%)
                  </p>
                </div>
              </div>
            </div>
          </td>

          <td nowrap class="precio">
            <div [ngSwitch]="currentUser.tieneDtoCateg">
              <!-- CLIENTE SIN DESCUENTO => PRECIO NETO -->
              <div *ngSwitchCase="0">
                $ {{ item.precioneto | number:'1.2-2' }} <br>
              </div>
              <!-- CLIENTE CON tieneDtoCateg => MONTO DTO CLIENTE -->
              <div *ngSwitchCase="1">
                $ {{ (item.preciocategorizado) | number:'1.2-2' }}
                <br>
                <div *ngIf="item.precioneto != item.preciocategorizado">
                  <p class="info">( dto. Categorizado {{ 100 - ( item.preciocategorizado / item.precioneto ) * 100 |
                    number: '1.0-0' }}% )</p>
                </div>

              </div>
            </div>
          </td>
          <td nowrap class="precio">
            <!-- SUB TOTAL -->
            $ {{ item.preciocategorizado * item.cantidad | number:'1.2-2' }}
          </td>
          <td>
            <button type="button" class="btn btn-danger" (click)="deleteCarritoItem(item)">
              <i class="fa fa-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>