<div class="calibri">
    <app-navbar></app-navbar>

    <div class="padding">
        <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>

    <div class="whatsapp" >
        <a href="https://wa.me/+5491131755214" target="_blank">
          <div class="whatsapp-icon">
            <i class="fa fa-whatsapp"></i>
          </div>
        </a>
      </div>
</div>