import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Cliente } from 'src/app/_class/users/cliente';
import { Descuento } from 'src/app/_class/order/descuento';
import { PedidoItem } from 'src/app/_class/order/pedidoItem';
import { TicketPedido } from 'src/app/_class/order/ticketPedido';

@Component({
  selector: 'app-carrito-ticket',
  templateUrl: './carrito-ticket.component.html',
  styleUrls: ['./carrito-ticket.component.css']
})
export class CarritoTicketComponent  {

  public subtotal = 0;
  public impoteConDtoCliente = 0;

  public ticketPedido: TicketPedido = {};

  @Input() currentUser: Cliente =  {};
  @Input() pedidoItems: PedidoItem[] = [];
  @Input() descuento: Descuento = {};
  @Input() soloDetalle = false;


  @Output() lanzaCerrarPedido = new EventEmitter();

  constructor() { }

  public cerrarPedido() {
    this.lanzaCerrarPedido.emit({ ticketPedido: this.ticketPedido });
  }

/*
  public getSubTotalLista() {
    if (this.pedidoItems) {
      this.ticketPedido.subtotalLista = this.pedidoItems.reduce((acum, item) => acum + (item.cantidad * item.preciolista), 0);
      return this.ticketPedido.subtotalLista;
    }
    return 0;
  }*/
  
  public getSubTotalNeto() {
    if (this.pedidoItems) {
      this.ticketPedido.subtotalNeto = this.pedidoItems.reduce((acum, item) => acum + (item.cantidad * item.precioneto), 0);
      return this.ticketPedido.subtotalNeto;
    }
    return 0;
  }



  public getImporteConDtoCategorizado(): number {
    if (this.pedidoItems) {
      this.ticketPedido.dtoCategorizado = this.ticketPedido.subtotalNeto - this.pedidoItems.reduce((acum, item) => acum + (item.cantidad * item.preciocategorizado), 0);
      return this.ticketPedido.dtoCategorizado;
    }
    return 0;
  }

  public getImporteFinal(): number {
    if (this.pedidoItems) {
      this.ticketPedido.totalFinal = ( this.getSubTotalNeto() - this.getImporteConDtoCategorizado() ) * .9 ;
      return this.ticketPedido.totalFinal;
    }
    return 0;
  }
}
