import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CarritoItem } from 'src/app/_class/order/carritoItem';

@Injectable({
  providedIn: 'root'
})
export class CarritoItemsService {
  ItemsCollection: AngularFirestoreCollection<CarritoItem>;
  ItemDoc: AngularFirestoreDocument<CarritoItem>;
  Items: Observable<CarritoItem[]>;

  public cantCarritoItems: number;

  constructor(public db: AngularFirestore) {
    this.ItemsCollection = this.db.collection('CarritoItems');
    this.Items = this.ItemsCollection.snapshotChanges().pipe(map(actions => {
      return actions.map(a => {
        const data = a.payload.doc.data() as CarritoItem;
        data.id = a.payload.doc.id;
        return data;
      });
    }));
  }

  public getItems() {
    return this.Items;
  }

  public deleteItem(Item: CarritoItem) {
    this.ItemDoc = this.db.doc(`CarritoItems/${Item.id}`);
    this.ItemDoc.delete();
  }

  public addItem(Item: CarritoItem) {
    this.ItemsCollection.add(Item);
  }

  public updateItem(Item: CarritoItem) {
    this.ItemDoc = this.db.doc(`CarritoItems/${Item.id}`);
    this.ItemDoc.update(Item);
  }

  public getMCarritoItemsPorAlumno(alumno) {
    return this.db.collection("CarritoItems").ref.where("CarritoItem.crusada.alumno", "array-contains", alumno).get();
  }

}

