export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyB2LyPg6h0LpIpvR7ZRAwKNkDvj2ZOQEeU",
    authDomain: "carritoweb-97846.firebaseapp.com",
    databaseURL: "https://carritoweb-97846.firebaseio.com",
    projectId: "carritoweb-97846",
    storageBucket: "carritoweb-97846.appspot.com",
    messagingSenderId: "1010520583697",
    appId: "1:1010520583697:web:28c113555ac0f10f800bb0",
    measurementId: "G-QZVXRT2GCF"
  }
}
