import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ExportExcelService {

  exportExcel(excelData) {

    // Datos para el Excel
    const title = "Pedido Web Meyro";
    const headers = excelData.headers;
    const data = excelData.data;
    const cliente = excelData.idCliente;
    const razonSocial = excelData.razonSocial;
    const fecha = excelData.fechaHora;
    const nro = excelData.pedidoNro;

    // Crear un workbook con una hoja de cálculo
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Pedido Detalle');

    // Añadir Título y formateo
    worksheet.mergeCells('A1', 'E1');
    let titleRow = worksheet.getCell('A1');
    titleRow.value = title;
    titleRow.font = { name: 'Calibri', size: 16, bold: true, color: { argb: '0085A3' } };
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' };

    // Añadir información del cliente
    worksheet.addRow([]);
    worksheet.addRow(['Pedido Nro:', nro]);
    worksheet.addRow(['Pedido Cta:', cliente]);
    worksheet.addRow(['Razón Social:', razonSocial]);
    worksheet.addRow(['Fecha:', fecha]);
    worksheet.addRow([]);

    // Añadir Fila de Encabezado
    let headerRow = worksheet.addRow(headers);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF0000' },
      };
      cell.font = { bold: true, color: { argb: 'FFFFFF' }, size: 12 };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });

    // Ajustar anchos de columna
    worksheet.getColumn(1).width = 14; // Código de Artículo
    worksheet.getColumn(2).width = 7;  // Cantidad (Ajustado para 5 dígitos)
    worksheet.getColumn(3).width = 35; // Descripción (Aumentado para acomodar más texto)
    worksheet.getColumn(4).width = 14; // Precio Unitario
    worksheet.getColumn(5).width = 14 // Subtotal

    // Agregar datos con formato
    data.forEach(rowData => {
      let row = worksheet.addRow(rowData);
      row.eachCell((cell, number) => {
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
      });
    });

    worksheet.addRow([]);

    // Fila de pie de página
    let footerRow = worksheet.addRow(['Gracias por su compra.']);
    footerRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFB050' }
    };
    worksheet.mergeCells(`A${footerRow.number}:E${footerRow.number}`);
    footerRow.alignment = { vertical: 'middle', horizontal: 'center' };

    // Generar y guardar el archivo Excel
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'pedido nro ' + nro + ' cliente ' + cliente + ' fecha ' + fecha + '.xlsx');
    });
  }
}
