<div class="row">

  <div class="col-sm-9">
    <h1>Carrito de Compras</h1>
  </div>

  <div class="col-sm-3">
    <p>{{ hoy | date: 'dd / MMM / y' }}</p>
  </div>
</div>


<div class="row">
  <div class="col-sm-4">
    <h2> <b>{{ currentUser.idCliente }} - {{ currentUser.razonSocial }}</b></h2>
  </div>
  <!--
  <div class="col-sm-4">
    <select class="form-select" aria-label="Default select example">
      <option selected>Direccion de envio</option>
      <option *ngFor="let sucursal of sucursales">
        {{ sucursal.nombreSucursal }}
      </option>
    </select>
  </div>

  <div class="col-sm-4">
    <select class="form-select" aria-label="Default select example">
      <option selected>Medio de Envio:</option>
      <option *ngFor="let item of expresos">
        {{ item.nombre }}
      </option>
    </select>
  </div>
  -->

</div>




