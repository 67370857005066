import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
////////////// CLASS....................................................................
import { Cliente } from 'src/app/_class/users/cliente';
import { CarritoItem } from 'src/app/_class/order/carritoItem';
import { Pedido } from 'src/app/_class/order/pedido';
import { Descuento } from 'src/app/_class/order/descuento';
////////////// SERVICES.................................................................
// import { ClientesService } from 'src/app/_services/clientes.service';
import { AuthService } from 'src/app/_auth/auth.service';
import { DescuentosPHPService } from 'src/app/_services/php/descuentosPHP.service';
import { ExportExcelService } from 'src/app/_services/export-excel.service';
import { PedidosItemsPHPService } from 'src/app/_services/php/pedidoItemsPHP.service';
import { PedidoItem } from 'src/app/_class/order/pedidoItem';
import { DescuentoCliente } from 'src/app/_class/order/descuentoCliente';
import { descuentosClientePHPService } from 'src/app/_services/php/descuentosClientePHP.service';
import { PedidosPHPService } from 'src/app/_services/php/pedidosPHP.service';
import { Router } from '@angular/router';
import { Card } from 'src/app/_class/products/card';
import { CardsService } from 'src/app/_services/cards.service';
import { clientesPHPservice } from 'src/app/_services/php/clientesPHP.service';
import { Articulo } from 'src/app/_class/products/articulo';
import { dtosCliente } from 'src/app/_class/order/dtosCliente';


@Component({
  selector: 'app-carrito-page',
  templateUrl: './carrito-page.component.html',
  styleUrls: ['./carrito-page.component.css'],
})
export class CarritoPageComponent implements OnInit {

  public user$: Observable<any> = this.authSvc.afAuth.user;
  public currentUser: Cliente = {};

  public isCliente: boolean = false;
  public clientes: Cliente[] = [];


  public pedido: Pedido = {};

  public pedidoItem: PedidoItem = {};
  public pedidoItems: PedidoItem[] = [];

  public cards: Card[] = [];

  public descuento: Descuento = {};
  public descuentos: Descuento[] = [];

  public clienteDescuentos: DescuentoCliente[] = [];
  public dataForExcel = [];
  public dataFiltrada = [];

  constructor(
    private authSvc: AuthService,
    private exportExcelServicelSv: ExportExcelService,
    private cardsSv: CardsService,
    ///////////////// PHP  //////////////////////////////////////////// 
    private clientesPHPsv: clientesPHPservice,
    private pedidoItemsPHPSv: PedidosItemsPHPService,
    private descuentosPHPSv: DescuentosPHPService,
    private clienteDescuentosSv: descuentosClientePHPService,
    private pedidoSv: PedidosPHPService,
    private router: Router
  ) { }


  observacionesForm = new FormGroup({
    observaciones: new FormControl('', [
      Validators.maxLength(100)
    ]),
  })

  public getCards() {
    this.cardsSv.ListarO().subscribe(res => {
      this.cards = res;
    });
  }

  ///////////////////////////////////////// user ////////////////////////////////////////  

  public cargarDataFiltrada(event) {
    this.dataFiltrada = event.itemsEnviados;
  }


  public getDescuentoClientes() {
    this.clienteDescuentosSv.gets().subscribe(res => {
      this.clienteDescuentos = res;
    });
  }

  public getCurrentUser() {
    this.user$.subscribe((cu) => {
      this.clientesPHPsv.gets().subscribe(res => {
        this.clientes = res;
        this.currentUser = this.clientes.find(cte => cte.uid == cu.uid);
      });
    });
  }


  private getPreciocategorizado(articulo: Articulo, cliente: Cliente): number{
    let descuentoCliente: dtosCliente = {};


    //////////////////// DESARROLLAR SI NO EXISTE DESCUENTO ASIGNAR COEFICIENTE UNO **********************************************************************

    descuentoCliente = (this.clienteDescuentos.filter( cd => cd.idCliente == cliente.idCliente )).find( dc => dc.idProducto == articulo.idProducto );

    


    let coef = this.descuentos.find( dto => dto.id == descuentoCliente ).coeficiente;
    return articulo.precioNeto * coef
  }

  //////////////////////////////  carrito   //////////////////////////////////////////////


  public addCarrito(event): any {

    this.pedidoItem.idPedido = -1;
    this.pedidoItem.idCliente = event.cliente.idCliente;
    this.pedidoItem.idArticulo = event.articulo.idArticulo;
    this.pedidoItem.cantidad = event.cantidad;
    this.pedidoItem.idProducto = event.articulo.idProducto;
    this.pedidoItem.aplicacion = event.articulo.idAplicacion;
    this.pedidoItem.descripcionCorta = event.articulo.descripcionCorta;
    this.pedidoItem.preciolista = event.articulo.precioLista;
    this.pedidoItem.precioneto = event.articulo.precioNeto;
    this.pedidoItem.preciocategorizado = this.getPreciocategorizado(event.articulo, this.currentUser);





    //console.log(event);
    console.log(this.pedidoItem);
    
/*

    this.pedidoItemsPHPSv.save(this.pedidoItem).subscribe(res => {
      console.log(res);
    });


*/




    /*
    //  cargo descuento categorizado si es que tiene
    if (event.cliente.tieneDtoCateg == '1') {
      this.clienteDescuentos.forEach(e => {
        if (e.idProducto == event.articulo.idProducto && e.idCliente == event.cliente.idCliente) {
          this.descuentosSv.getById(e.idDescuento).subscribe(res => {
            this.pedidoItem.preciocategorizado = res.coeficiente * event.articulo.precioNeto;
            console.log(this.pedidoItem.preciocategorizado, res.coeficiente, event.articulo.precioNeto);
          });
        }
      });
    } else {
      this.pedidoItem.preciocategorizado = 1 * event.articulo.precioNeto;
    }
    */


    /*

    // gurarda o update depende si ya está en el carrito
    this.pedidoItemsSv.gets().subscribe(res => {
      if (res.filter(e => e.idPedido == -1 && e.idArticulo == event.articulo.idArticulo && e.idCliente == this.currentUser.id).length) {
        this.pedidoItem.cantidad = res.filter(e => e.idArticulo == event.articulo.idArticulo && e.idPedido == -1 && e.idCliente == this.currentUser.idCliente)[0].cantidad + event.cantidad;

        this.pedidoItemsSv.update(res.filter(e => e.idArticulo == event.articulo.idArticulo && e.idPedido == -1 && e.idCliente == this.currentUser.idCliente)[0].id, this.pedidoItem).subscribe(res => {
          console.log('UPDATE', res);
        });

      } else {
        this.pedidoItem.cantidad = event.cantidad;
        this.pedidoItemsSv.save(this.pedidoItem).subscribe(res => {
          console.log('SAVE', res);
        });
      }
    });

    */

    
    


    

  }


  exportToExcel() {
    let data = [];

    this.pedidoItemsPHPSv.gets().forEach(element => {
      data.push({
        art: element.articulo.idArticulo,
        cant: element.cantidad
      });
    });

    data.forEach((row: any) => {
      this.dataForExcel.push(Object.values(row))
    })

    let reportData = {
      title: 'Pedido Cta: ' + this.currentUser.id + ' Fecha: ' + this.getfecha() + ' Expreso: ' + 'Reparto MEYRO',
      data: this.dataForExcel,
      headers: Object.keys(data[0])
    }
    this.exportExcelServicelSv.exportExcel(reportData);
  }

  public updateItemCarrito(event) {
    this.pedidoItemsPHPSv.update(event.id, event.itemUpdate);
    console.log('UPDATE');
    this.getCarritoItems();
  }

  public deleteItemCarrito(event) {
    this.pedidoItemsPHPSv.delete(event.id).subscribe(res => {
      console.log(res);
      this.getCarritoItems();
    });
  }

  public getCarritoItems() {
    this.pedidoItemsPHPSv.gets().subscribe(pedidoItemsRES => {
      this.user$.subscribe((cu) => {
        this.clientesPHPsv.gets().subscribe(res => {
          this.clientes = res;
          this.currentUser = this.clientes.find(cte => cte.uid == cu.uid);

          this.pedidoItems = pedidoItemsRES.filter(e => e.idCliente == this.currentUser.idCliente && e.idPedido == -1);
        });
      });
      /*
            this.user$.subscribe((usr) => {
              this.clientesSv.getClienteByUid(usr.uid).subscribe((cu) => {
                this.pedidoItems = res.filter(e => e.idCliente == cu.id && e.idPedido == -1);
              });
            });
            */
    });
  }

  public cerrarPedido(event) {
    let pedido: Pedido = {
      estado: 2,  // NOLEIDO
      fechaHora: this.getToday(),
      idCliente: this.currentUser.idCliente,
      idExpreso: 1,
      idSucursal: 1,
      observaciones: ""
    }

    //console.table(pedido);

    
    this.pedidoSv.save(pedido).subscribe(res => {
      this.pedido = res;
      console.log(this.pedido);

      
      this.pedidoItemsPHPSv.updateidpedido(this.pedido).subscribe(res => {
        console.log(res);
        this.getCarritoItems();
      });

    });
    
  }

  public getPedidoAbiertoCliente() {
    this.pedidoSv.gets().subscribe(res => {
      let pedido = res.filter(e => e.estado == 1 && e.cliente.idCliente == this.currentUser.idCliente);

      return pedido;
    });
  }

  public getfecha() {
    let fecha = new Date;

    const dia = fecha.getDate().toString();
    const mes = (fecha.getMonth() + 1).toString();
    const anio = fecha.getFullYear().toString();

    return dia + '/' + mes + '/' + anio;
  }

  public getDescuentos() {
    this.descuentosPHPSv.gets().subscribe(res => {
      this.descuentos = res;
    });
  }

  public getToday() {
    let fecha = new Date;

    const dia = fecha.getDate().toString();
    const mes = (fecha.getMonth() + 1).toString();
    const anio = fecha.getFullYear().toString();
    const hora =
      fecha.getHours() < 10
        ? '0' + fecha.getHours().toString()
        : fecha.getHours().toString();
    const minutos =
      fecha.getMinutes() < 10
        ? '0' + fecha.getMinutes().toString()
        : fecha.getMinutes().toString();

    return dia + '/' + mes + '/' + anio + ' ' + hora + ':' + minutos;
  }

  private checkCliente() {
    this.user$.subscribe((cu) => {

      this.clientesPHPsv.gets().subscribe(res => {
        this.clientes = res;
        this.currentUser = this.clientes.find(cte => cte.uid == cu.uid);

        this.currentUser.estado == 1 ? this.isCliente = true : this.router.navigate(['permisoDenegado']);
      });
    });
  }

  ngOnInit(): void {
    this.checkCliente();
    window.scroll(0, 0);

    this.getCarritoItems();
    this.getDescuentoClientes();
    this.getDescuentos();
    this.getCurrentUser();
    this.getCards();

  }
}