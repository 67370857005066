import { Cliente } from 'src/app/_class/users/cliente';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { clientesPHPservice } from 'src/app/_services/php/clientesPHP.service';

@Component({
  selector: 'app-usuarios-listado',
  templateUrl: './usuarios-listado.component.html',
  styleUrls: ['./usuarios-listado.component.css'],
})
export class UsuariosListadoComponent implements OnInit {

  @Output() pasaItem = new EventEmitter();
  @Output() verCliente = new EventEmitter();
  @Output() carritoCliente = new EventEmitter();
  @Output() clienteUpdate = new EventEmitter();


  @Input() clientes: Cliente[] = [];

  public clientesFilter: Cliente[] = [];

  public x: number;
  public paramSearch: string = '';
  public userMostrar: Cliente = {};

  constructor( private clientesPHPSv: clientesPHPservice) { this.x = 0; }

  public getAll(): any {
    this.paramSearch = '';

    this.clientesPHPSv.gets().subscribe( res => {
      this.clientes = res;
      this.clientesFilter = res.filter( cte => cte.estado == 1 );
    });

    this.clientesFilter = this.clientes;
  }

  public getsByParams() {
    console.log(this.paramSearch.toLowerCase());

    this.clientesFilter = this.clientes.filter(c => -1 !== (c.idCliente + c.cuit + c.razonSocial + c.localidad + c.calleAndNumero + c.email).toLowerCase().indexOf(this.paramSearch.toLowerCase()));
  }

  public getAllEstado(event): any {
    switch (event.target.value) {
      case '1':
        this.clientesFilter = this.clientes.filter((p) => p.estado == 1);
        break;
      case '0':
        this.clientesFilter = this.clientes.filter((p) => p.estado == 0);
        break;
      case '3':
        this.clientesFilter = this.clientes;
        break;
      default:
        this.clientesFilter = this.clientes;
        break;
    }
  }

  public getAllRegistrados(event): any {
    switch (event.target.value) {
      case 'registrado':
        this.clientesFilter = this.clientesFilter.filter((c) => c.email != null && c.email.length >= 2);
        break;
      case 'noregistrado':
        this.clientesFilter = this.clientes.filter((p) => p.email == null || p.email.length <= 2);
        break;
      case '':
        this.clientesFilter = this.clientes;
        break;
      default:
        this.clientesFilter = this.clientes;
        break;
    }
  }


  public update(item: Cliente, estado) {
    item.estado = estado;
    this.clienteUpdate.emit(item);
  }

  public capCliente(event) {
    this.verCliente.emit({ cliente: event });
  }

  public carritearCliente(event) {
    this.carritoCliente.emit(event);
  }

  ngOnInit(): void {
    this.getAll();
  }
}
