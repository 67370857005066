<div class="container mt-3" (keyup.enter)="getsByParams()">
  <!-- FILTERS -->
  <div class="row">
    <div class="col-sm-4">
      <label for="inputState">Buscador: </label>

      <div class="input-group mb-2">
        <input type="text" class="form-control" id="inlineFormInputGroup" [(ngModel)]="paramSearch"
          placeholder=" Buscar por código, CUIT o razón social">
        <div class="input-group-prepend">
          <div class="input-group-text"><img src="/assets/icons/magnifying-glass-solid.svg" alt="" srcset=""
              (click)="getsByParams()"></div>
        </div>
        <div class="input-group-prepend">
          <div class="input-group-text"><img src="/assets/icons/circle-xmark-solid.svg" alt="" srcset=""
              (click)="getAll()"></div>
        </div>
      </div>

    </div>

    <div class="col-sm-4">
      <div class="row">
        <div class="form-group col-sm-6">
          <label for="inputState"><span><b>Está habilitado??:</b></span></label>
          <select id="inputState" class="form-control" (change)="getAllEstado($event)">
            <option value="3">Todos</option>
            <option value="1">Habilitados</option>
            <option value="0">Deshabilitados</option>
          </select>
        </div>

        <div class="form-group col-sm-6">
          <label for="inputState"><span><b>Está registrado??: </b></span></label>
          <select id="inputState" class="form-control" (change)="getAllRegistrados($event)">
            <option value="">Todos</option>
            <option selected value="registrado">Registrados</option>
            <option value="noregistrado">no Registrados</option>
          </select>
        </div>

      </div>
    </div>

    <div class="col-sm-4">
      <pagination-controls (pageChange)="x = $event" previousLabel="Previo" nextLabel="Siguiente">
      </pagination-controls>
    </div>

  </div>

  <hr>

  <!-- TABLE -->
  <div class="row">

    <div class="col-sm-12">
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col">Cliente</th>
            <th scope="col">Direccion</th>
            <th scope="col">Contacto</th>
            <th scope="col">Credenciales</th>
            <th scope="col">Accion</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of clientesFilter  | paginate: { itemsPerPage: 30, currentPage: x }">

            <td>
              <b>{{ item.idCliente }}</b> {{ item.razonSocial }}
              <br><b>cuit :</b> {{ item.cuit }}
            </td>

            <td>
              {{ item.calleAndNumero }} {{ item.localidad }}
              <br> <b>Prov.: </b> {{ item.provincia }}
            </td>

            <td>
              {{ item.comprador }} {{ item.telefonoCelular }}
              <br> <b>Te. Linea:</b> {{ item.telefonoLinea }}
            </td>

            <td>
              {{ item.email }}
              <br><b>Contraseña: </b>{{ item.password }}
              <div [ngSwitch]="item.emailVerified">
                <div *ngSwitchCase="1">
                  <span class="badge bg-success">email verificado</span>
                </div>

                <div *ngSwitchCase="0">
                  <span class="badge bg-danger">email sin verificar</span>
                </div>
              </div>
            </td>
            <td>
              <div [ngSwitch]="item.estado">
                <div *ngSwitchCase="0">
                  <mat-slide-toggle (click)="update(item, 1)">
                    <span class="badge bg-danger">Deshabilitado</span>
                  </mat-slide-toggle>
                </div>
                <div *ngSwitchCase="1">
                  <mat-slide-toggle checked="checked" color="red" (click)="update(item, 0)">
                    <span class="badge bg-success">Habilitado</span>
                  </mat-slide-toggle>
                </div>
              </div>
              <button type="button" class="btn btn-primary btn-sm" (click)="capCliente(item)">
                <i class="fa fa-eye"></i>
              </button>
              &nbsp;
              <button type="button" class="btn btn-success btn-sm" (click)="carritearCliente(item)">
                <i class="fa fa-shopping-cart"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- PAGINATOR -->
  <div class="row">
    <div class="col-sm-12">
      <pagination-controls (pageChange)="x = $event" previousLabel="Previo" nextLabel="Siguiente">
      </pagination-controls>
    </div>
  </div>
</div>