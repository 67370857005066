<div class="container mt-3">

  <ng-template matStepLabel>Tus Datos</ng-template>

  <h2>


    <!-- TITLE -->
    <div class="row">
      <hr>
      <div class="col-sm-10">
        <h3 class="fs-3">Cuenta : {{ viewUser.idCliente }}</h3>
      </div>

      <div class="col-sm-2">
        <button class="btn btn-sm btn-danger" (click)="cambiarVista()">
          <i class="fa fa-chevron-left"></i>
          Volver a Listado
        </button>
      </div>
    </div>


  </h2>

  <div class="row">

    <!-- ////////////////////////////  RAZON SOCIAL //////////////////////////////////////////////// -->
    <div class="col-sm-6 p-2">
      <label for="year">Razon Social :</label>
      <input type="text" class="form-control" [value]="viewUser.razonSocial" />
    </div>

    <!-- ////////////////////////////  CALLE Y NRO //////////////////////////////////////////////// -->
    <div class="col-sm-6 p-2">
      <label for="year">Calle y Numero :</label>
      <input type="text" class="form-control" [value]="viewUser.calleAndNumero" />
    </div>

    <!-- ////////////////////////////  LOCALIDAD //////////////////////////////////////////////// -->
    <div class="col-sm-9 p-2">
      <label for="year">Localidad :</label>
      <input type="text" class="form-control" [value]="viewUser.localidad" />
    </div>

    <!-- ////////////////////////////  CP //////////////////////////////////////////////// -->
    <div class="col-sm-3 p-2">
      <label for="year">Codigo Postal :</label>
      <input type="text" class="form-control" [value]="viewUser.cp" />
    </div>

    <!-- ////////////////////////////  PROVINCIA //////////////////////////////////////////////// -->
    <div class="col-sm-7 p-2">
      <label for="year">Provincia :</label>
      <input type="text" class="form-control" [value]="viewUser.provincia" />
    </div>

    <!-- ////////////////////////////  TELEFONO //////////////////////////////////////////////// -->
    <div class="col-sm-5 p-2">
      <label for="year">Telefono :</label>
      <input type="text" class="form-control" [value]="viewUser.telefonoLinea" />
    </div>

    <!-- ////////////////////////////  COMPRADOR //////////////////////////////////////////////// -->
    <div class="col-sm-6 p-2">
      <label for="year">Responsable de Compras :</label>
      <input type="text" class="form-control" [value]="viewUser.comprador" />
    </div>

    <!-- ////////////////////////////  CELULAR //////////////////////////////////////////////// -->
    <div class="col-sm-6 p-2">
      <label for="year">Telefono Celular :</label>
      <input type="text" class="form-control" [value]="viewUser.telefonoCelular" />
    </div>

    <!-- ////////////////////////////  MAIL //////////////////////////////////////////////// -->
    <div class="col-sm-5 p-2">
      <label for="year">email :</label>
      <input type="text" class="form-control" [value]="viewUser.email" />
    </div>

    <div class="col-sm-4 p-2">
      <label for="year">pass :</label>
      <input type="text" class="form-control" [value]="viewUser.password" />
    </div>

    <div class="col-sm-3 p-2" [ngSwitch]="viewUser.tieneDtoCateg">
      <label for="year">Categorizado :</label>
      <div *ngSwitchCase="0">
        <button type="button" class="btn btn-success btn-sm" (click)="updateTieneDtoCategorizado()">
          <i class="fa fa-solid fa-toggle-off"></i>
          &nbsp;
          <b>Cargar Descuentos Categorizado</b>
        </button>
      </div>

      <div *ngSwitchCase="1">
        <button type="button" class="btn btn-danger btn-sm" (click)="updateTieneDtoCategorizado()">
          <i class="fa fa-solid fa-toggle-on"></i>
          &nbsp;
          <b>Quitar Descuentos Categorizado</b>
        </button>
      </div>

    </div>
  </div>

  <!-- ////////////////////////////  CATEGORIZADO //////////////////////////////////////////////// -->
  <hr>
  <!-- TITLE -->
  <div class="row" *ngIf="viewUser.tieneDtoCateg != '0'">
    <div class="col-sm-12">
      <p class="fs-3">Descuentos</p>
    </div>
    <!--  <div  *ngIf="isAdmin">  -->
    <div class="col-sm-12">
      <app-cliente-descuentos (lanzaCreateDto)="updateClienteDtoCategorizado($event)" [viewUser]="viewUser"
        [descuentos]="descuentos" [productos]="productos" [descuentosCliente]="descuentosCliente">
      </app-cliente-descuentos>
    </div>
  </div>
</div>