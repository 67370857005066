import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CarritoAdminPageComponent } from './pages/carrito-admin-page/carrito-admin-page.component';

import { CarritoPageComponent } from './pages/carrito-page/carrito-page.component';


const routes: Routes = [
  { path: '', component: CarritoPageComponent },
  { path: 'carritoadmin', component: CarritoAdminPageComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CarritoRoutingModule { }
